import { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { CustomModal } from 'common/components/CustomModal';
import { useLoader } from 'common/providers/LoaderProvider';
import { toastify } from 'common/components/Toastify';
import Password from 'common/components/form/Password';
import { updateUserPassword } from 'workspace/api/users.service';
import { useAuth } from 'workspace/providers/auth/AuthContext';

const PasswordResetModal = ({ showModal, onClose, userId }) => {
  const { logout } = useAuth();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const { showLoader, hideLoader } = useLoader();

  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;

  const handleSubmit = async () => {
    setPasswordError('');
    setError('');

    // Form Validation
    if (!password) {
      setPasswordError('Password is required');
      return;
    } else if (!passwordPattern.test(password)) {
      setPasswordError(
        'Your password must be at least 8 characters long and include a mix of uppercase and lowercase letters, numbers, and special characters.'
      );
      return;
    }

    if (!confirmPassword) {
      setError('Please confirm your password');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      showLoader();
      await updateUserPassword(userId, password);
      toastify.showSuccessToast('Your password has been updated successfully.');
      onClose();
      setTimeout(() => {
        logout();
      }, 4000);
    } catch (apiError) {
      toastify.showWarningToast(apiError?.message);
    } finally {
      hideLoader();
    }
  };

  const handleClose = () => {
    setError('');
    setPasswordError('');
    setConfirmPassword('');
    setPassword('');
    onClose();
  };

  return (
    <CustomModal show={showModal} size="sm" centered={false} onHide={handleClose}>
      <CustomModal.Header>
        <FormattedMessage id="profile.resetPassword" />
      </CustomModal.Header>
      <CustomModal.Body>
        <Form>
          <Form.Group controlId="formPassword" className="mb-2">
            <Form.Label className="ws-form-label">
              <FormattedMessage id="profile.newPassword" />
              <span className="required-asterisk">*</span>
            </Form.Label>
            <Password placeholder="Enter new password" value={password} onChange={e => setPassword(e.target.value)} />
            {passwordError && <div className="text-danger mt-2">{passwordError}</div>}
          </Form.Group>
          <Form.Group controlId="formConfirmPassword" className="mb-2">
            <Form.Label className="ws-form-label">
              <FormattedMessage id="profile.confirmPassword" />
              <span className="required-asterisk">*</span>
            </Form.Label>
            <Password
              placeholder="Confirm new password"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
            />
          </Form.Group>

          {error && <div className="text-danger mt-2">{error}</div>}
        </Form>
      </CustomModal.Body>
      <CustomModal.Footer>
        <Button variant="secondary" size="sm" onClick={handleClose}>
          <FormattedMessage id="profile.cancel" />
        </Button>
        <Button variant="primary" size="sm" onClick={handleSubmit}>
          <FormattedMessage id="profile.reset" />
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  );
};

export default PasswordResetModal;
