import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLoader } from 'common/providers/LoaderProvider';
import { toastify } from 'common/components/Toastify';
import { useAppContext } from 'test-builder/context/AppContext';
import { getUserBooksByID } from 'test-builder/services/book.service';
import { getUserBooks } from 'test-builder/services/book.service';
import { getPublisherTestsByBookId } from 'test-builder/services/testcreate.service';

const MigratedTests = () => {
  const intl = useIntl();
  const { selectedTest, dispatchEvent } = useAppContext();
  const { showLoader, hideLoader } = useLoader();
  const [isOpen, setIsOpen] = useState();
  const [migratedBooks, setMigratedBooks] = useState(null);

  /**
   * Toggles the visibility of migrated tests and loads them if necessary.
   */
  const handleMigratedTestsToggle = () => {
    setIsOpen(prevIsOpen => {
      // Loads migrated tests books if they are not already loaded and the toggle is being opened.
      if (!prevIsOpen && !migratedBooks) {
        loadMigratedTests();
      }

      return !prevIsOpen;
    });
  };

  /**
   * Loads migrated tests and updates the state accordingly.
   */
  const loadMigratedTests = async () => {
    try {
      showLoader();
      const bookIds = await getUserBooks();
      const bookDetails = await Promise.all(bookIds.map(getUserBooksByID));

      setMigratedBooks(bookDetails);
    } catch (error) {
      if (error?.status === 404) {
        toastify.showWarningToast(intl.formatMessage({ id: 'warning.NoBooksAdded' }));
      } else {
        toastify.showErrorToast(error?.message);
      }
    } finally {
      hideLoader();
    }
  };

  /**
   * Handles the click event to open a book.
   * Fetches tests for the book if not already available and toggles the book's open state.
   *
   * @param {Object} book - The book object to be opened.
   */
  const handleOpenBookClick = async book => {
    showLoader();

    try {
      // Check if tests are not already fetched for the book
      if (!book.isOpen && !book.tests) {
        // Fetch tests for the book
        book.tests = await getPublisherTestsByBookId(book.guid);
      }

      // Toggle the book's open state
      setMigratedBooks(prevBooks => {
        return prevBooks.map(item => {
          if (item.guid === book.guid) {
            return { ...book, isOpen: !book.isOpen };
          }
          return item;
        });
      });
    } catch (error) {
      console.error(`Error fetching tests for book ID ${book.guid}:`, error);
    } finally {
      hideLoader();
    }
  };

  /**
   * Handles the migration test view click.
   *
   * @param {Object} test - The test to be viewed.
   */
  const handleMigratedTestView = test => {
    // Create a new test object with the updated property
    const migratedTest = { ...test, isMigrated: true };

    // Dispatch an event to handle the view or edit test click
    dispatchEvent('HANDLE_VIEW_OR_EDIT_TEST_CLICK', migratedTest);
  };

  return (
    <div className="migrated-tests-container">
      <div className="migrated-tests-header migrated-book-node" tabIndex="0">
        <i className={`fa ${isOpen ? 'fa-caret-down' : 'fa-caret-right'}`} onClick={handleMigratedTestsToggle}></i>
        <span>
          <FormattedMessage id="migratedtests" />
        </span>
      </div>
      {isOpen && (
        <div className="migrated-tests-books">
          {migratedBooks?.map(book => (
            <React.Fragment key={book.guid}>
              <div className="migrated-book-node" tabIndex="0">
                <i
                  className={`fa ${book.isOpen ? 'fa-caret-down' : 'fa-caret-right'}`}
                  onClick={() => handleOpenBookClick(book)}
                ></i>
                <span>{book.title}</span>
              </div>

              {book.isOpen && (
                <div className="migrated-tests">
                  {book.tests?.map(test => (
                    <div key={test.guid} className="migrated-book-node" tabIndex="0">
                      {test.title}
                      <button
                        className={`view-icon ${selectedTest?.testId === test.guid ? 'selected' : ''}`}
                        title={intl.formatMessage({ id: 'message.view', defaultMessage: 'View' })}
                        onClick={() => handleMigratedTestView(test)}
                      >
                        <i className="bi bi-eye"></i>
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default MigratedTests;
