import { useEffect } from 'react';
import QuestionTemplate from '../components/QuestionTemplate/QuestionTemplate';

const QuestionTemplatePage = () => {
  useEffect(() => {
    document.title = 'Question Template';
  }, []);

  return <QuestionTemplate />;
};

export default QuestionTemplatePage;
