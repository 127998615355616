import React from 'react';
import { useDrag } from 'react-dnd';
import { useIntl } from 'react-intl';
import DraggableIcon from 'common/components/DraggableIcon';
import QuestionMapper from 'test-builder/components/questions/QuestionMapper';

const DraggableQuestion = ({ question, index, selectedQuestionId, handleAdd }) => {
  const intl = useIntl();
  const [, drag, dragPreview] = useDrag({
    type: 'SAVED_QUESTION',
    item: { question },
  });

  const key = question.guid;

  return (
    <div ref={dragPreview}>
      <DraggableIcon ref={drag} />
      <div className="d-flex w-100 align-items-start" key={key}>
        <div className="question-area flex-grow-1">
          <QuestionMapper questionNode={question} index={index} />
        </div>
        <button
          className={`questionaddforquestionbank ${selectedQuestionId === question.guid ? 'selected' : ''}`}
          onClick={() => handleAdd(question)}
          title={intl.formatMessage({ id: 'addQuestionsIcon', defaultMessage: 'Add Questions' })}
        >
          <i className="bi bi-plus-lg darker-icon"></i>
        </button>
      </div>
    </div>
  );
};

export default DraggableQuestion;
