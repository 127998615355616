import axios from 'test-builder/api/axios';

const url = process.env.REACT_APP_API_URL;
const PAGE_COUNT = Number(process.env.REACT_APP_MAX_QUESTIONS_TO_LOAD_AT_ONCE);

export const getUserQuestionFolders = () => {
  return axios.get(`${url}/my/questionfolders`);
};

export const getUserQuestionFoldersRoot = () => {
  return axios.get(`${url}/my/questionfoldersroot`);
};

export const saveUserQuestionFolder = folder => {
  return axios.post(`${url}/my/questionfolders`, folder);
};

export const updateUserQuestionFolders = folders => {
  return axios.put(`${url}/my/questionfolders`, folders);
};

export const getChildQuestionFolders = folderId => {
  return axios.get(`${url}/my/questionfolders/${folderId}/folders`);
};

export const getUserQuestions = (folderId, pageNumber) => {
  let apiUrl = `${url}/my/questions?folderId=${folderId}`;

  if (pageNumber) {
    apiUrl = `${apiUrl}&pageNumber=${pageNumber}&pageCount=${PAGE_COUNT}`;
  }

  return axios.get(apiUrl);
};

export const getAllQuestions = async (bookid, nodeId, queryParams = {}) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestUrl = `${url}/books/${bookid}/nodes/${nodeId}/questions${queryString ? `?${queryString}` : ''}`;

  return axios.get(requestUrl);
};

export const swapQuestionBetweenFolders = (sourceFolderId, destinationFolderId, questionId) => {
  const endpoint = `${url}/my/folders/${sourceFolderId}/folders/${destinationFolderId}/questions/${questionId}`;

  return axios.put(endpoint);
};
