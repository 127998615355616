class Auth {
  static instance = null;

  constructor() {}

  /**
   * Returns the singleton instance of the Auth class.
   * If the instance doesn't exist, it is created and stored for future use.
   *
   * @returns {Auth} The singleton instance of the Auth class.
   */
  static getInstance() {
    return Auth.instance || (Auth.instance = new Auth());
  }

  /**
   * Initializes the piSession with the provided options and sets up event listeners for token refresh and session rejuvenation.
   */
  initialize() {
    const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
    if (window.piSession) {
      const options = {
        sessionIdleTimeoutSeconds: 3600,
        sessionIdleTimeoutWarningSeconds: 600,
        useDefaultIdleTimoutWarningPopup: true,
        requireLogin: true,
        loginSuccessUrl: window.location.href,
        avoidReInit: true,
      };
      window.piSession.initialize(clientId, options);
      window.piSession.monitorUserActivity(true);

      const handleTokenUpdate = token => {
        window.sessionStorage.setItem('token', token);
        window.sessionStorage.setItem('tokenExpiry', window.piSession.currentTokenExpiry());
      };

      window.piSession.on(window.piSession.RefreshEvent, response => {
        handleTokenUpdate(response.data);
      });

      window.piSession.on(window.piSession.SessionRejuvenatedEvent, () => {
        handleTokenUpdate(window.piSession.currentToken());
      });
    }
  }

  /**
   * Logs in the user and returns a Promise that resolves with the token.
   */
  login() {
    return new Promise((resolve, reject) => {
      window.piSession.getToken((status, token) => {
        if (status === window.piSession.Success) {
          sessionStorage.setItem('token', token);
          sessionStorage.setItem('SmsUserId', window.piSession.smsUserId());
          sessionStorage.setItem('userId', window.piSession.userId());
          sessionStorage.setItem('tokenExpiry', window.piSession.currentTokenExpiry());

          resolve(token);
        } else if ([window.piSession.NoSession, window.piSession.NoToken, window.piSession.TimedOut].includes(status)) {
          this.logout();
        } else {
          reject(status);
        }
      });
    });
  }

  /**
   * Logs out the user by clearing session and local storage, and logging out of piSession if it exists.
   */
  logout() {
    // Clear session storage
    sessionStorage.clear();

    // Clear local storage
    localStorage.clear();

    // Check if piSession object exists and logout if it does
    if (window.piSession) {
      window.piSession.logout();
    }
  }

  /**
   *
   * @returns current authentication token
   */
  getToken() {
    return window.sessionStorage.getItem('token') || window.piSession.currentToken();
  }

  /**
   * Checks if the token has expired
   *
   * @returns {boolean} True if the token has expired, false otherwise
   */
  isTokenExpired() {
    const tokenExpiry = sessionStorage.getItem('tokenExpiry');
    if (tokenExpiry) {
      const expiryDate = new Date(tokenExpiry);
      return Date.now() >= expiryDate.getTime();
    }

    return true;
  }
}

export default Auth.getInstance();
