import React from 'react';
import { QUESTION_COMPONENTS } from 'common/components/questions/question-mapping';
import { FILTER_KEYS_MAP } from 'common/constants/question-constants';
import { useManageQuestionsContext } from 'workspace/providers/manage-questions/ManageQuestionsProvider';

const QuestionMapper = props => {
  const { questionViewButtonClicked, questionEditButtonClicked, questionDeleteButtonClicked } =
    useManageQuestionsContext();
  const userMetadata = Object.keys(FILTER_KEYS_MAP);

  const onSubmit = payload => {
    questionViewButtonClicked(payload);
  };

  const onEdit = index => {
    questionEditButtonClicked(index);
  };

  const onDelete = index => {
    questionDeleteButtonClicked(index);
  };

  return React.createElement(QUESTION_COMPONENTS[props.questionNode.quizType], {
    ...props,
    userMetadata,
    onSubmit,
    onEdit,
    onDelete,
  });
};

export default QuestionMapper;
