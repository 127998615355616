import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form } from 'react-bootstrap';
import { useAppContext } from '../context/AppContext';
import QuestionsBanksTips from './TestTabs/QuestionsBanksTips/QuestionsBanksTips';
import TreeViewTestCreate from './TreeViewTestCreate';
import QuestionDropContainer from 'test-builder/components/QuestionDropContainer';
import RandomizeTestVersionModal from './modals/RandomizeTestVersion/RandomizeTestVersionModalPopup';
import { sanitizeTitle } from '../utils/test-utils';
import { deepClone } from 'common/utils';
import { toastify } from 'common/components/Toastify';
import { transformQuestionTemplateToQuestion } from 'common/utils/questions-utils';
import { isTestReadyToSave, isTestModified } from '../utils/test-utils';
import { QUESTION_TYPES } from 'common/constants/question-constants';
import './TestCreate.css';
import '../../styles/_tables.css';

const MAX_QUESTIONS_COUNT = process.env.REACT_APP_TEST_QUESTIONS_COUNT;

const TestCreate = () => {
  const { selectedTest, dispatchEvent, testTabsDispatch, testsMasterData } = useAppContext();
  const [tabTitle, setTabTitle] = useState(selectedTest?.title || '');
  const [isEditing, setIsEditing] = useState(true);
  const intl = useIntl();
  const [showRandomizeTestVersionModal, setShowRandomizeTestVersionModal] = useState(false);

  useEffect(() => {
    setTabTitle(selectedTest?.title || '');
  }, [selectedTest]);

  const handleTitleChange = event => {
    const title = sanitizeTitle(event.target.value);

    setTabTitle(title);
    setIsEditing(true);

    // Update the title in the selectedTest object
    if (selectedTest && selectedTest.id) {
      // Dispatch an action to update the selectedTest object in the context
      testTabsDispatch({ type: 'UPDATE_SELECTED_TEST_TITLE', payload: { title } });
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (tabTitle.trim().length === 0) {
      return;
    } else {
      testTabsDispatch({ type: 'UPDATE_SELECTED_TEST_TITLE', payload: { title: tabTitle } });
      setIsEditing(false);
    }
  };

  const handleQuestionDrop = item => {
    if (selectedTest.questions.length >= MAX_QUESTIONS_COUNT) {
      const msg = intl.formatMessage({ id: 'warning.morequestions' }, { count: MAX_QUESTIONS_COUNT });
      toastify.showWarningToast(msg);
      return;
    }

    let newQuestion = deepClone(item);

    if (newQuestion.isQuestionTemplate) {
      delete newQuestion.isQuestionTemplate;
      newQuestion = transformQuestionTemplateToQuestion(newQuestion);
    }

    dispatchEvent('ADD_NEW_QUESTION_TO_TEST', newQuestion);

    setIsEditing(true);
  };

  const handleTestNewVersion = () => {
    if (isTestModified(selectedTest, testsMasterData)) {
      toastify.showWarningToast(intl.formatMessage({ id: 'testversions.save.before' }));
      return;
    }
    if (
      selectedTest.questions.length <= 1 &&
      selectedTest.questions.some(
        q => q.quizType === QUESTION_TYPES.Essay || q.quizType === QUESTION_TYPES.FillInBlanks
      )
    ) {
      toastify.showWarningToast(intl.formatMessage({ id: 'testversions.cannotbe.created' }));
      return;
    }
    setShowRandomizeTestVersionModal(true);
  };

  const handleClose = () => {
    setShowRandomizeTestVersionModal(false);
  };

  const disableNewVersionBtn = useMemo(
    () => selectedTest?.questions?.length === 0 || !isTestReadyToSave(selectedTest) || selectedTest?.isMigrated,
    [selectedTest, testsMasterData]
  );

  return (
    <>
      <div className="test-container pb-0">
        <div className="d-flex align-items-center test-name-edit-container p1">
          <div className="gap-2 d-flex align-items-center w-100">
            <div className="ml-1 p-500">
              <FormattedMessage id="testName" />
            </div>
            <Form className="flex-grow-1" onSubmit={handleSubmit}>
              <Form.Control
                type="text"
                name="title"
                placeholder="Enter Test title"
                value={tabTitle}
                onChange={handleTitleChange}
                className="rounded testNameInputBox"
                required={true}
              />
            </Form>
            <Button onClick={handleTestNewVersion} disabled={disableNewVersionBtn}>
              <FormattedMessage id="testcreate.newversion" />
            </Button>
          </div>
        </div>
      </div>
      <div className="test-questions-container">
        {selectedTest?.questions?.length ? (
          <>
            <div className="pt-0">
              <TreeViewTestCreate data={selectedTest.questions} />
            </div>
            <QuestionDropContainer onDrop={handleQuestionDrop}>
              <div className="drag-container align-items-center d-flex justify-content-center">
                <FormattedMessage id="message.dragQuestionsHere" />
              </div>
            </QuestionDropContainer>
          </>
        ) : (
          <QuestionDropContainer onDrop={handleQuestionDrop}>
            <QuestionsBanksTips />
          </QuestionDropContainer>
        )}
        {showRandomizeTestVersionModal && <RandomizeTestVersionModal handleClose={handleClose} />}
      </div>
    </>
  );
};

export default TestCreate;
