import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDrop } from 'react-dnd';
import { Tree } from '@minoru/react-dnd-treeview';
import './QuestionDropContainer.css';

const dummyTreeData = [
  {
    id: 1,
    parent: 0,
    droppable: true,
  },
];

/**
 * A container component that handles dropping questions.
 *
 * @param {Object} props
 * @param {ReactNode} props.children - The child elements to render.
 * @param {Function} props.onDrop - A callback function to call when a question is dropped.
 */
const QuestionDropContainer = ({ children, onDrop }) => {
  const location = useLocation();
  const [isYourQuestionsTab, setIsYourQuestionsTab] = useState(false);

  /**
   * Updates the `isYourQuestionsTab` state based on the current location.
   */
  useEffect(() => {
    setIsYourQuestionsTab(location.pathname === '/home/questions');
  }, [location]);

  /**
   * Handles dropping a question.
   *
   * @param {Object} item - The dropped item.
   */
  const handleQuestionDrop = item => {
    onDrop(item.question);
  };

  /**
   * Handles dropping a question from the "Your Questions" tab.
   *
   * @param {Object} _newTreeData - The new tree data.
   * @param {Object} item - The dropped item.
   */
  const handleQuestionDropFromYourQuestions = (_newTreeData, item) => {
    onDrop(item.dragSource.data);
  };

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ['QUESTION_TEMPLATE', 'TREE_NODE', 'SAVED_QUESTION'],
    drop: handleQuestionDrop,
    collect: monitor => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <div className="question-drop-container">
      {isYourQuestionsTab ? (
        <Tree
          rootId={0}
          tree={dummyTreeData}
          canDrag={() => false}
          canDrop={(_, node) => node.dragSource.isQuestion}
          onDrop={handleQuestionDropFromYourQuestions}
          render={() => <>{children}</>}
        />
      ) : (
        <div ref={drop}>{children}</div>
      )}
    </div>
  );
};

export default QuestionDropContainer;
