import React, { useEffect, useState } from 'react';
import { Tree } from '@minoru/react-dnd-treeview';
import { useIntl } from 'react-intl';

import { useLoader } from 'common/providers/LoaderProvider';
import { toastify } from 'common/components/Toastify';
import DraggableNode from './DraggableNode';
import { useAppContext } from 'test-builder/context/AppContext';
import { getChaptersByBookID } from 'test-builder/services/book.service';
import { generateChapters, loadQuestions } from './utils';
import { constructSearchQueryParams, isAdvancedSearchApplied } from '../utils';

function AdvancedSearchTreeView({ lastSelectedQuestion, handleAdd }) {
  const intl = useIntl();
  const {
    questionBanksData: { advancedSearchSelection, selectedBookNodes, selectedBookIds },
  } = useAppContext();
  const { showLoader, hideLoader } = useLoader();

  const [filteredTreeData, setFilteredTreeData] = useState([]);
  const [initialOpen, setInitialOpen] = useState([]);
  const [loadedChaprters, setLoadedChapters] = useState(new Set());

  useEffect(() => {
    if (isAdvancedSearchApplied(advancedSearchSelection) && selectedBookIds.length) {
      setLoadedChapters(new Set());
      loadChaptersUnderBooks();
    }
  }, [advancedSearchSelection, selectedBookIds]);

  /**
   * Loads chapters under the selected books.
   */
  const loadChaptersUnderBooks = async () => {
    showLoader();
    const books = selectedBookNodes.filter(node => selectedBookIds.includes(node.id));
    try {
      const queryParams = constructSearchQueryParams(advancedSearchSelection);
      // Fetch chapters for the selected books
      const chaptersForBooks = await Promise.allSettled(
        books.map(book => getChaptersByBookID(book.bookGuid, queryParams))
      );

      // Flatten the chapters array
      const flattenedChapters = chaptersForBooks.reduce((acc, chapters, index) => {
        if (chapters.status === 'fulfilled') {
          return acc.concat(generateChapters(chapters.value, books[index]));
        }

        return acc;
      }, []);

      if (flattenedChapters.length === 0) {
        toastify.showWarningToast(intl.formatMessage({ id: 'warning.noContentFound' }));
      }

      // Filter the selected book nodes based on the discipline type
      const disciplines = selectedBookNodes.filter(
        node => node.type === 'discipline' && books.some(book => book.parent === node.id)
      );

      // Set the filtered tree data and search term presence
      setFilteredTreeData([...disciplines, ...books, ...flattenedChapters]);
      setInitialOpen([...disciplines.map(node => node.id)]);
    } catch (error) {
      toastify.showErrorToast(error?.message);
    } finally {
      hideLoader();
    }
  };

  const handleNodeClick = async clickedNode => {
    const isChapter = clickedNode.type !== 'discipline' && clickedNode.type !== 'book';

    if (clickedNode.droppable && isChapter && !loadedChaprters.has(clickedNode.id)) {
      showLoader();
      const questions = await loadQuestions(clickedNode, advancedSearchSelection);

      setLoadedChapters(chapeters => chapeters.add(clickedNode.id));
      setFilteredTreeData(filteredTreeData => [...filteredTreeData, ...questions]);
      hideLoader();
    }
  };

  return (
    <div className="question-banks-treeview">
      <Tree
        tree={filteredTreeData}
        sort={false}
        rootId={0}
        render={(node, { isOpen, onToggle }) => (
          <DraggableNode
            key={node.id}
            node={node}
            isOpen={isOpen}
            onToggle={onToggle}
            onDataUpdate={handleNodeClick}
            isClicked={selectedBookIds.includes(node.id)}
            lastSelectedQuestion={lastSelectedQuestion}
            handleAdd={handleAdd}
          />
        )}
        initialOpen={initialOpen}
      />
    </div>
  );
}

export default AdvancedSearchTreeView;
