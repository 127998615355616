import { forwardRef, useState } from 'react';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './draggable-icon.css';

const DraggableIcon = forwardRef((_, ref) => {
  const [grabbing, setGrabbing] = useState(false);

  return (
    <div
      ref={ref}
      onMouseDown={() => setGrabbing(true)}
      onMouseUp={() => setGrabbing(false)}
      className={`draggable-icon ${grabbing ? 'c-grabbing' : ''}`}
    >
      <FontAwesomeIcon icon={faEllipsisVertical} />
      <FontAwesomeIcon icon={faEllipsisVertical} />
    </div>
  );
});

export default DraggableIcon;
