import { createContext, useContext } from 'react';

const YourQuestionsContext = createContext(undefined);

export const useYourQuestionsContext = () => {
  const context = useContext(YourQuestionsContext);

  if (context === undefined) {
    throw new Error('useYourQuestionsContext must be used within a EditorProvider');
  }

  return context;
};

export default YourQuestionsContext;
