import { AxiosError } from 'axios';
import DOMPurify from 'dompurify';

/**
 * Extracts the error details from an Axios error object.
 *
 * @param {AxiosError} error - The Axios error object to extract the message from.
 * @returns {{ status: number, message: string }} The extracted error message and status.
 */
export const extractErrorDetails = error => {
  if (!(error instanceof AxiosError)) {
    return { status: null, message: error.message };
  }

  const { response } = error;
  let message = '';
  let status = null;

  if (response) {
    status = response.status;

    if (response.data && response.data.message) {
      message = response.data.message;
    } else {
      message = `Request failed with status code ${response.status}`;
    }
  } else {
    message = error.message;
  }

  return { status, message };
};

/**
 * Creates a deep clone of an object or value.
 *
 * @param {*} data The object or value to clone.
 * @returns {*} A deep clone of the input data.
 */
export const deepClone = data => {
  if (typeof data !== 'object' || data === null) {
    return data; // primitive types or null, no need to clone
  }
  return JSON.parse(JSON.stringify(data));
};

/**
 * Sanitizes HTML data using DOMPurify.
 *
 * @param {string} data The HTML data to sanitize.
 * @returns {{ __html: string }} An object with a single property `__html` containing the sanitized HTML.
 */
export const sanitizedData = data => ({
  __html: DOMPurify.sanitize(data),
});

/**
 * Formats a date string into the format DD/MM/YYYY.
 *
 * @param {string} dateString The date string to format.
 * @returns {string|null} The formatted date string, or null if the input is invalid.
 */
export const formatDate = dateString => {
  if (!dateString) return null;

  const date = new Date(dateString);
  if (isNaN(date.getTime())) return null;

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

/**
 * Decodes special HTML characters, including numeric and named entities, while preserving HTML structure if present.
 *
 * @param {string} input - The string containing HTML entities or HTML content.
 * @returns {string} - The decoded string with special characters replaced and HTML structure preserved.
 *
 * @example
 * // Decodes mixed entities in plain text
 * decodeSpecialCharacters("50&amp;#x2013;55 times per minute");
 * // Output: "50–55 times per minute"
 *
 * @example
 * // Decodes and preserves HTML content
 * decodeSpecialCharacters(`
 *   <table>
 *     <tr><td>&nbsp;</td></tr>
 *     <tr><td>&#x2013;</td></tr>
 *   </table>
 * `);
 * // Output: "<table><tr><td> </td></tr><tr><td>–</td></tr></table>"
 */
export function decodeSpecialCharacters(input) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, 'text/html');

  if (!doc.body) {
    return '';
  }

  // Check if the input contains HTML tags
  const hasHTMLTags = /<\/?[a-z][\s\S]*>/i.test(input);

  if (hasHTMLTags) {
    // Decode and preserve HTML structure
    return doc.body.innerHTML.replace(/&nbsp;/g, ' ').trim();
  } else {
    // Decode plain text entities
    const intermediate = doc.body.textContent || '';
    const finalDoc = parser.parseFromString(intermediate, 'text/html');
    return finalDoc.body.textContent.trim();
  }
}

/**
 * Converts an array of bindings into a sequence map.
 *
 * @param {Array<Object>} bindings - The array of bindings to convert.
 * @param {string} key - The key to use for mapping the bindings.
 * @returns {Object} A sequence map where each key is a binding key and the value is the corresponding sequence.
 */
export const convertToSequenceMap = (bindings, key) => {
  return bindings.reduce((map, binding) => {
    map[binding[key]] = binding.sequence;
    return map;
  }, {});
};

/**
 * Checks if the source is a parent of the target in a tree data structure.
 *
 * @param {Array} treeDataAsArray - The tree data represented as an array of objects.
 * @param {number|string} sourceId - The ID of the source node.
 * @param {number|string} targetId - The ID of the target node.
 * @returns {boolean} True if the source is a parent of the target, false otherwise.
 */
export const isSourceIsParentOfTarget = (treeDataAsArray, sourceId, targetId) => {
  let parent = targetId;

  while (parent && parent !== 0) {
    if (parent === sourceId) {
      return true;
    }
    parent = treeDataAsArray.find(node => node.id === parent)?.parent;
  }

  return false;
};
