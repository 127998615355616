import axios from 'test-builder/api/axios';

const url = process.env.REACT_APP_API_URL;

export const saveTestFolder = folder => {
  return axios.post(`${url}/my/testfolders`, folder);
};

export const updateTestFolder = folder => {
  return axios.put(`${url}/my/testfolders`, folder);
};

export const swapTestBetweenFolders = (sFolderId, dFolderId, testID) => {
  return axios.put(`${url}/my/folders/${sFolderId}/folders/${dFolderId}/tests/${testID}`);
};

export const getRootTestFolders = () => {
  return axios.get(`${url}/my/testfolders`);
};

export const getUserTestFolders = folderId => {
  return axios.get(`${url}/my/folders/${folderId}/folders`);
};

export const deleteTestFolder = folderId => {
  return axios.delete(`${url}/folders/${folderId}`);
};

export const deleteTestFromFolder = (folderId, testId) => {
  return axios.delete(`${url}/folders/${folderId}/tests/${testId}`);
};
