import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Dropdown, DropdownButton, Nav } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomModal } from 'common/components/CustomModal';
import { useAppContext } from '../../context/AppContext';
import Test from '../../entities/Test.Entity';
import { getFolderTests, saveMyQuestions, saveMyTest } from '../../services/testcreate.service';
import { toastify } from 'common/components/Toastify';
import SaveAsModalPopup from '../modals/SaveAs/SaveAsModalPopup';
import PrintTestModalpopup from './PrintTest/PrintTestModalpopup';
import ExportModalPopup from '../modals/Export/ExportModalPopup';
import { generateNewTestTitle, isTestModified, isTestReadyToSave } from '../../utils/test-utils';
import { buildQuestionEnvelop } from 'common/utils/questions-utils';
import { publish } from 'common/utils/events';
import { deepClone } from 'common/utils';
import './TestTabs.css';

const MAX_ALLOWED_TEST_TABS = process.env.REACT_APP_MAX_ALLOWED_TEST_TABS_COUNT;

const TestTabs = () => {
  const intl = useIntl();
  const { tests, testsMasterData, selectedTest, selectedFolderWhileSave, testTabsDispatch } = useAppContext();

  const isTabCloseIconClick = useRef(false);

  const [showSaveAsModal, setShowSaveAsModal] = useState(false);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [showModalExport, setShowModalExport] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);

  // creates a new test when test tabs count is zero
  useEffect(() => {
    if (tests.length === 0) {
      handleAddNewTestTab();
    }
  }, [tests.length]);

  const handleTestSelection = item => {
    testTabsDispatch({ type: 'ACTIVATE_TEST_TAB', payload: item });
  };

  const handleAddNewTestTab = event => {
    event?.preventDefault();

    if (tests.length >= MAX_ALLOWED_TEST_TABS) {
      const msg = intl.formatMessage({ id: 'warning.moretabs' }, { count: MAX_ALLOWED_TEST_TABS });
      toastify.showWarningToast(msg);
      return;
    }

    const newTest = new Test();
    newTest.title = generateNewTestTitle(tests);

    // Add the new test
    testTabsDispatch({ type: 'ADD_NEW_TEST', payload: newTest });
  };

  const handleRemoveTabItem = (e, test) => {
    if (isTestModified(test, testsMasterData)) {
      isTabCloseIconClick.current = true;
      setShowDeleteConfirmationModal(true);
    } else {
      removeTab(e, test);
      setShowDeleteConfirmationModal(false);
    }
  };

  const handleCancel = () => {
    isTabCloseIconClick.current = false;
    setShowDeleteConfirmationModal(false);
  };

  const handleDontSave = (e, testSelected) => {
    removeTab(e, testSelected);
    setShowDeleteConfirmationModal(false);
    isTabCloseIconClick.current = false;
  };

  const removeTab = (e, testTobeRemoved) => {
    e?.preventDefault();
    e?.stopPropagation();

    if (!testTobeRemoved) {
      // If testSelected is undefined, handle the case gracefully
      console.error('Error: testSelected is undefined');
      return;
    }

    testTabsDispatch({ type: 'REMOVE_TEST_FROM_TABS', payload: testTobeRemoved });

    setShowDeleteConfirmationModal(false);
  };

  const handleSave = async (activeTest, isFromSaveAsModal) => {
    try {
      // clone the active test to avoid modifying the original object
      const test = deepClone(activeTest);

      // if the test is not ready to save, then show warning message
      if (!isTestReadyToSave(test)) {
        setShowDeleteConfirmationModal(false);
        toastify.showWarningToast(intl.formatMessage({ id: 'warning.noQuestionsOrNotInEditState' }));
        return;
      }

      // if we are saving the test from Save As modal, then set the folderGuid to the selected folder
      if (isFromSaveAsModal) {
        test.folderGuid = selectedFolderWhileSave || null;
        test.isMigrated = false;
      }

      // Check for duplicate test
      const isduplicateTest = await isDuplicateTest(test, isFromSaveAsModal);

      // If test is duplicate, show confirmation modal
      if (isduplicateTest) {
        toastify.showWarningToast(intl.formatMessage({ id: 'warning.duplicateTest' }));
      } else {
        // Proceed to save when its update or new test
        if (!test.title.trim()) {
          // If the test title is empty or only contains whitespace, set it to a default value
          toastify.showWarningToast(intl.formatMessage({ id: 'warning.testNameEmpty' }));
          return;
        }
        // Reset Guid of test if its save As
        if (isFromSaveAsModal) {
          test.testId = null;
          test.metadata.guid = null;
        }

        // save questions and get question bindings
        const questionBindings = await saveQuestions(test);

        // publish event to reload your questions tab
        publish('reload_your_questions');

        if (questionBindings && questionBindings.length) {
          await saveTest(test, questionBindings);
        }
      }
      setShowDeleteConfirmationModal(false);
    } catch (error) {
      toastify.showErrorToast(intl.formatMessage({ id: 'error.somethingWentWrong' }));
    }
  };

  const saveTest = async (test, questionBindings) => {
    // Building the json to create the test.
    const testcreationdata = {
      metadata: {
        crawlable: 'true',
      },
      body: {
        '@context': 'http://purl.org/pearson/paf/v1/ctx/core/StructuredAssignment',
        '@type': 'StructuredAssignment',
        assignmentContents: {
          '@contentType': 'application/vnd.pearson.paf.v1.assignment+json',
          binding: [],
        },
      },
    };

    testcreationdata.body.title = test.title;
    testcreationdata.body.guid = null; // TODO : Set this value

    // TODO: Update this logic based on understanding of what testID represents
    if (test.testId != null || test.metadata.guid != null) {
      testcreationdata.metadata = test.metadata;
      if (test.testId) {
        testcreationdata.metadata.guid = test.testId;
      } else {
        testcreationdata.metadata.guid = test.metadata.guid;
      }
    }

    testcreationdata.metadata.title = test.title;
    testcreationdata.body.assignmentContents.binding = questionBindings;

    try {
      let testResult = await saveMyTest(testcreationdata, test.folderGuid);
      // update GUID the saved test object
      if (testResult) {
        // Update selected test with saved test details
        const savedTest = deepClone(test);
        savedTest.testId = testResult.guid;
        savedTest.metadata.guid = testResult.guid;
        savedTest.questions = [
          ...savedTest.questions.map((question, index) => ({
            ...question,
            guid: questionBindings[index].guid,
            itemId: questionBindings[index].guid,
            masterData: deepClone(question.qtiModel),
          })),
        ];

        // Updating selected test with testID
        testTabsDispatch({ type: 'UPDATE_SELECTED_TEST_AFTER_SAVE', payload: savedTest });

        toastify.showSuccessToast(intl.formatMessage({ id: 'success.testSaved' }));
        setShowSaveAsModal(false);

        if (isTabCloseIconClick.current) {
          removeTab(null, selectedTest);
        }

        // publish event to reload your questions tab
        publish('reload_your_tests');
      }
    } catch (error) {
      console.error('Error saving Test:', error);
      if (error?.status === 409) {
        toastify.showWarningToast(error.message);
      } else {
        toastify.showErrorToast(intl.formatMessage({ id: 'error.testSave' }));
      }
    } finally {
      isTabCloseIconClick.current = false;
      testTabsDispatch({ type: 'CLEAR_SELECTED_FOLDER_IN_SAVE_AS_POPUP' });
    }
  };

  const saveQuestions = async test => {
    // Save the Question Envelops & return the question bindings to attach to test
    // TODO - Add additional logic from legacy app as needed

    try {
      let userSettings = {};
      const questionEnvelops = test.questions?.map(question => buildQuestionEnvelop(question, userSettings)) || [];

      const questionResults = await saveMyQuestions(questionEnvelops);

      return questionResults.map((result, index) => ({
        guid: result.guid,
        activityFormat: 'application/vnd.pearson.qti.v2p1.asi+xml',
        bindingIndex: index,
      }));
    } catch (error) {
      console.error('Error saving Questions:', error);
      if (error?.status === 409) {
        toastify.showErrorToast(error.message);
      } else {
        toastify.showErrorToast(intl.formatMessage({ id: 'error.testSave' }));
      }

      return [];
    }
  };

  /**
   * Check whether test is duplicate or not
   * @param {*} test - test object
   * @param {*} isFromSaveAsModal - flag to check whether test is from save as modal
   * @returns true when test is duplicate, false otherwise
   */
  const isDuplicateTest = async (test, isFromSaveAsModal) => {
    const existingTests = await getFolderTests(test.folderGuid);
    if (!existingTests || existingTests.length === 0) {
      // No tests found, proceed accordingly
      return;
    }

    // if it is a new test, then check for duplicate test
    if (!test.testId) {
      return existingTests.some(existingTest => existingTest.title.trim() === test.title.trim());
    }

    // if it is an existing test, then check for duplicate test
    return existingTests.some(
      existingTest => existingTest.title.trim() === test.title.trim() && existingTest.guid !== test.testId
    );
  };

  const handleCloseModal = e => {
    setShowSaveAsModal(!showSaveAsModal);
    testTabsDispatch({ type: 'CLEAR_SELECTED_FOLDER_IN_SAVE_AS_POPUP' });

    e?.preventDefault();
    e?.stopPropagation();
  };

  const handleSaveAs = () => {
    if (!isTestReadyToSave(selectedTest)) {
      toastify.showWarningToast(intl.formatMessage({ id: 'warning.noQuestionsOrNotInEditState' }));
      return;
    }
    setShowSaveAsModal(true);
    setShowDeleteConfirmationModal(false);
  };

  const handleShowModalExport = () => {
    if (!isTestReadyToSave(selectedTest)) {
      toastify.showWarningToast(intl.formatMessage({ id: 'warning.noQuestionsOrNotInEditState' }));
      return;
    }

    if (isTestModified(selectedTest, testsMasterData)) {
      toastify.showWarningToast(intl.formatMessage({ id: 'warning.saveTestBeforeExport' }));
      return;
    }

    if (selectedTest.testId) {
      setShowModalExport(true);
    } else {
      toastify.showWarningToast(intl.formatMessage({ id: 'warning.saveTestBeforeExport' }));
    }
  };

  const handlePrint = () => {
    // Open print modal
    if (!isTestReadyToSave(selectedTest)) {
      toastify.showWarningToast(intl.formatMessage({ id: 'warning.noQuestionsOrNotInEditState' }));
      return;
    }
    setShowPrintModal(true);
  };

  const disableSave = useMemo(() => selectedTest?.isMigrated || selectedTest?.metadata?.versionOf, [selectedTest]);

  return (
    <div className="tab-container">
      <div className="d-flex flex-column flex-sm-row justify-content-between">
        <h4 className="p-1">
          <FormattedMessage id="testtabs.title" />
        </h4>
        <div className="p-1 d-flex flex-column flex-sm-row align-items-start align-items-sm-center">
          <Button className="btn-test mr-1" id="btn-test-wizard">
            <i className="fa-solid fa-wand-magic-sparkles"></i>
            <FormattedMessage id="testtabs.testwizard" />
          </Button>

          <div className="d-flex flex-column flex-sm-row align-items-start">
            <DropdownButton id="dropdown-item-button" title="Save" className="btn-test mb-1 mb-sm-0 mr-sm-1 mr-1">
              <Dropdown.Item href="#" onClick={() => handleSave(selectedTest)} disabled={disableSave}>
                <FormattedMessage id="message.save" />
              </Dropdown.Item>
              <Dropdown.Item href="#" onClick={handleSaveAs}>
                <FormattedMessage id="message.saveAs" />
              </Dropdown.Item>
            </DropdownButton>

            <Button id="dropdown-item-button" className="btn-test mb-1 mb-sm-0 mr-sm-1 mr-1" onClick={handlePrint}>
              <FormattedMessage id="testtabs.print" />
            </Button>

            <Button className="btn-test mt-1 mt-sm-0" onClick={handleShowModalExport}>
              <FormattedMessage id="testtabs.export" />
            </Button>
            {showPrintModal && (
              <PrintTestModalpopup
                width="80%"
                show={showPrintModal}
                handleCloseModal={() => setShowPrintModal(false)}
              />
            )}
            <ExportModalPopup
              width="80%"
              show={showModalExport}
              selectedTest={selectedTest}
              handleCloseModal={() => setShowModalExport(false)}
            />
            {showSaveAsModal && (
              <SaveAsModalPopup
                show={showSaveAsModal}
                handleCloseModal={handleCloseModal}
                selectedTest={selectedTest}
                handleSave={handleSave}
              />
            )}
          </div>
        </div>
      </div>

      <div className="tabs-and-buttons-container">
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link
              href="#"
              onClick={handleAddNewTestTab}
              // disabled={tests.length >= maxAllowedTestTabsCount}
              className={'active custom-add-new-test'}
              aria-label="Add New Test"
              title={intl.formatMessage({ id: 'addNewTestTitle', defaultMessage: 'Add New Test' })}
            >
              <i className="fa-solid fa-plus"></i>
            </Nav.Link>
          </Nav.Item>
          <span className="tab-separator"> </span>
          {tests.slice(0, 4).map(test => (
            <Nav.Item key={test.id}>
              <Nav.Link
                onClick={() => {
                  handleTestSelection(test);
                }}
                className={selectedTest && selectedTest.id === test.id ? 'active' : ''}
                id="test-tabs-navlink"
              >
                <div className="tab-label">
                  <div className="test-title" title={test.title}>
                    {test.title.trim() !== '' ? test.title : 'Untitled'}
                  </div>
                  {/* Always render the close button */}
                  {tests.length > 1 && (
                    <div className="">
                      <Button
                        className="close-tab"
                        aria-label="close"
                        aria-roledescription=" "
                        variant="link"
                        onClick={e => handleRemoveTabItem(e, test)}
                        title=""
                      >
                        <i className="fas fa-times"></i>
                      </Button>
                    </div>
                  )}
                </div>
              </Nav.Link>
            </Nav.Item>
          ))}
          {tests.length > 4 && (
            <Nav.Item className="three-dots-link">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-ellipsis"
                  as={Nav.Link}
                  title={intl.formatMessage({ id: 'message.more', defaultMessage: 'More' })}
                >
                  <i className="fa-solid fa-ellipsis"></i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {tests.slice(4).map(test => (
                    <Dropdown.Item
                      key={test.id}
                      onClick={() => handleTestSelection(test)}
                      className={selectedTest && selectedTest.id === test.id ? 'active' : ''}
                    >
                      <div className="tab-label" id="tab-label-dropdown">
                        <div className="test-title" title={test.title}>
                          {test.title.trim() !== '' ? test.title : 'Untitled'}
                        </div>
                        {/* Always render the close button */}
                        {tests.length > 1 && (
                          <div className="close-tab-wrapper" aria-roledescription=" ">
                            <Button
                              className="close-tab"
                              id="close-tab-dropdown"
                              aria-label="close"
                              aria-roledescription=" "
                              variant="link"
                              onClick={e => handleRemoveTabItem(e, test)}
                              title=""
                            >
                              <i className="fas fa-times"></i>
                            </Button>
                          </div>
                        )}
                      </div>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Item>
          )}
        </Nav>
      </div>

      <CustomModal show={showDeleteConfirmationModal} centered={false} size="sm">
        <CustomModal.Header onClose={handleCancel}>
          <FormattedMessage id="testtabs.modalpopup.testsaved" />
        </CustomModal.Header>
        <CustomModal.Body>
          <FormattedMessage id="testtabs.modalpopup.savechanges" />
        </CustomModal.Body>
        <CustomModal.Footer>
          <Button
            variant="primary"
            name="save"
            onClick={() => (selectedTest.isMigrated ? handleSaveAs() : handleSave(selectedTest))}
          >
            <FormattedMessage id="message.save" />
          </Button>
          <Button variant="primary" onClick={e => handleDontSave(e, selectedTest)}>
            <FormattedMessage id="testtabs.modalpopup.dontsave" />
          </Button>
          <Button variant="secondary" onClick={handleCancel}>
            <FormattedMessage id="message.cancel" />
          </Button>
        </CustomModal.Footer>
      </CustomModal>
    </div>
  );
};

export default TestTabs;
