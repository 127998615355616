import { createContext, useState, useContext, useCallback } from 'react';
import ConfirmModal from './ConfirmModal';

// Create a context for the confirm dialog
const ConfirmDialogContext = createContext();

// Custom hook to access the confirm dialog context
// eslint-disable-next-line react-refresh/only-export-components
export const useConfirmDialog = () => {
  return useContext(ConfirmDialogContext);
};

// Provider component for the confirm dialog context
export const ConfirmDialogProvider = ({ children }) => {
  // State to manage the visibility of the confirm dialog
  const [isVisible, setIsVisible] = useState(false);

  // State to store the configuration of the confirm dialog
  const [dialogConfig, setDialogConfig] = useState({
    title: '',
    message: '',
    confirmText: '',
    cancelText: '',
    onConfirm: () => {},
    onCancel: () => {},
  });

  // Memoized function to show the confirm dialog with the provided configuration
  const showConfirmDialog = useCallback(config => {
    setDialogConfig({
      title: config.title,
      message: config.message || 'Are you sure?',
      confirmText: config.confirmText || 'Confirm',
      cancelText: config.cancelText,
      onConfirm: config.onConfirm,
      onCancel: config.onCancel,
    });
    setIsVisible(true);
  }, []);

  // Memoized function to handle cancel button click
  const handleCancel = useCallback(() => {
    setIsVisible(false);
    dialogConfig.onCancel();
  }, []);

  // Memoized function to handle confirm button click
  const handleConfirm = useCallback(() => {
    setIsVisible(false);
    dialogConfig.onConfirm();
  }, [dialogConfig]);

  // Return the provider component with the context value
  return (
    <ConfirmDialogContext.Provider value={{ showConfirmDialog }}>
      {children}
      {/* Render the modal only once, at a higher level */}
      {isVisible && <ConfirmModal config={dialogConfig} onConfirm={handleConfirm} onCancel={handleCancel} />}
    </ConfirmDialogContext.Provider>
  );
};
