import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomModal } from 'common/components/CustomModal';
import { toastify } from 'common/components/Toastify';
import { publish } from 'common/utils/events';
import { useLoader } from 'common/providers/LoaderProvider';
import { useAppContext } from 'test-builder/context/AppContext';
import { fetchMyTests } from 'test-builder/services/testcreate.service';
import { TEST_SCRAMBLE_TYPE } from 'test-builder/constants/randomize-test-constants';
import { QUESTION_TYPES } from 'common/constants/question-constants';
import './randomize-test-version-modal-popup.css';

const MAX_ALLOWED_TEST_TABS = process.env.REACT_APP_MAX_ALLOWED_TEST_TABS_COUNT;

const VERSION_OPTIONS = Array.from({ length: 10 }, (_, i) => ({
  value: i + 1,
  label: `${i + 1} Version${i > 0 ? 's' : ''}`,
}));

const RandomizeTestVersionModal = ({ handleClose }) => {
  const intl = useIntl();
  const { selectedTest, tests, dispatchEvent } = useAppContext();
  const [selectedVersions, setSelectedVersions] = useState(1);
  const [isViewVersionsChecked, setIsViewVersionsChecked] = useState(false);
  const [randomizeQuestions, setRandomizeQuestions] = useState(true);
  const [randomizeAnswers, setRandomizeAnswers] = useState(false);
  const { showLoader, hideLoader } = useLoader();

  const handleVersionChange = event => {
    setSelectedVersions(Number(event.target.value));
  };

  const handleViewVersionsChange = event => {
    setIsViewVersionsChecked(event.target.checked);
  };

  const handleAddNewTestTabs = async () => {
    showLoader();
    const exceedsLimit = tests.length + selectedVersions > MAX_ALLOWED_TEST_TABS;

    if (exceedsLimit && isViewVersionsChecked) {
      toastify.showWarningToast(intl.formatMessage({ id: 'warning.moretabs' }, { count: MAX_ALLOWED_TEST_TABS }));
      hideLoader();
      handleClose();
      return;
    }

    // Check if all questions are 'Essay' or 'Fill in the blanks' and answers randomization is enabled
    const allAreEssayOrFillBlanks = selectedTest.questions.every(
      question => question.quizType === QUESTION_TYPES.Essay || question.quizType === QUESTION_TYPES.FillInBlanks
    );

    if (allAreEssayOrFillBlanks && randomizeAnswers && !randomizeQuestions) {
      toastify.showWarningToast(intl.formatMessage({ id: 'warning.cannotRandomizeEssayOrFillBlanks' }));
      hideLoader();
      return;
    }

    let scrambleType = null;
    if (randomizeQuestions && randomizeAnswers) {
      scrambleType = TEST_SCRAMBLE_TYPE.BOTH;
    } else if (randomizeQuestions) {
      scrambleType = TEST_SCRAMBLE_TYPE.QUESTIONS;
    } else if (randomizeAnswers) {
      scrambleType = TEST_SCRAMBLE_TYPE.ANSWERS_KEY;
    }

    const randomizeTestData = {
      scrambleType: scrambleType,
      noOfVersions: selectedVersions,
    };

    try {
      let testsObj = await fetchMyTests(selectedTest.testId, randomizeTestData);
      testsObj = testsObj.map(test => ({
        ...test,
        versionOf: selectedTest.testId,
      }));
      if (isViewVersionsChecked) {
        for (const test of testsObj.slice(0, selectedVersions)) {
          dispatchEvent('HANDLE_VIEW_OR_EDIT_TEST_CLICK', test);
          await new Promise(resolve => setTimeout(resolve, 500));
        }
      }
      toastify.showSuccessToast(intl.formatMessage({ id: 'testscreated.successfully' }));

      // publish event to reload your tests tab
      publish('reload_your_tests');
      handleClose();
    } catch (error) {
      if (error?.status === 409) {
        toastify.showWarningToast(error.message);
      } else {
        toastify.showErrorToast(intl.formatMessage({ id: 'error.Error.RandomizeTest' }));
      }
    } finally {
      hideLoader();
    }
  };

  return (
    <CustomModal show size="sm" className="randomize-test-modal">
      <CustomModal.Header onClose={handleClose}>
        <FormattedMessage id="testcreate.randomize.modal.title" defaultMessage="Randomize Versions of Test" />
      </CustomModal.Header>
      <CustomModal.Body>
        <div>
          <Form.Check
            type="checkbox"
            id="questions"
            label={intl.formatMessage({ id: 'testcreate.randomize.modal.label.questions' })}
            checked={randomizeQuestions}
            onChange={() => setRandomizeQuestions(!randomizeQuestions)}
          />
          <Form.Check
            type="checkbox"
            id="answers"
            label={intl.formatMessage({ id: 'testcreate.randomize.modal.label.answers' })}
            checked={randomizeAnswers}
            onChange={() => setRandomizeAnswers(!randomizeAnswers)}
          />
        </div>
        <hr />
        <div className="version-select-container">
          <Form.Group controlId="formPublisher">
            <Form.Select name="versions" size="sm" onChange={handleVersionChange} value={selectedVersions}>
              {VERSION_OPTIONS.map(version => (
                <option key={version.value} value={version.value}>
                  {version.label}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Check
            type="checkbox"
            id="viewversions"
            label={intl.formatMessage({ id: 'testcreate.randomize.modal.label.viewversions' })}
            checked={isViewVersionsChecked}
            onChange={handleViewVersionsChange}
          />
        </div>
      </CustomModal.Body>
      <CustomModal.Footer>
        <Button variant="secondary" size="sm" onClick={handleClose}>
          <FormattedMessage id="message.cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          variant="primary"
          size="sm"
          onClick={handleAddNewTestTabs}
          disabled={!randomizeQuestions && !randomizeAnswers}
        >
          <FormattedMessage id="message.create" defaultMessage="Create" />
        </Button>
      </CustomModal.Footer>
    </CustomModal>
  );
};

export default RandomizeTestVersionModal;
