import { createContext, useContext, useEffect, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { useLoader } from 'common/providers/LoaderProvider';
import { toastify } from 'common/components/Toastify';
import { convertToSequenceMap, isSourceIsParentOfTarget } from 'common/utils';
import { useAppContext } from 'test-builder/context/AppContext';
import { getFolderTests, getRootTests } from 'test-builder/services/testcreate.service';
import {
  deleteTestFolder,
  getUserTestFolders,
  saveTestFolder,
  updateTestFolder,
  deleteTestFromFolder,
  swapTestBetweenFolders,
} from 'test-builder/services/testfolder.service';
import YourTestsReducer, { YOUR_TESTS_ACTIONS } from './YourTestsReducer';
import { convertTreeDataToArray } from './utils';

const MAX_TESTS_TO_LOAD_AT_ONCE = Number(process.env.REACT_APP_MAX_TESTS_TO_LOAD_AT_ONCE);

const initialState = {
  treeData: null, // data in tree structure
  treeDataAsArray: [], // flat array representation of data which TreeView component needs
  hasMore: false, // flag to check if more data is available to load
};

const YourTestsContext = createContext();

const YourTestsProvider = ({ children }) => {
  const intl = useIntl();
  const { showLoader, hideLoader } = useLoader();
  const [state, dispatch] = useReducer(YourTestsReducer, initialState);

  const { tests, testTabsDispatch } = useAppContext();

  useEffect(() => {
    if (state.treeData) {
      const treeDataAsArray = convertTreeDataToArray(state.treeData.children, state.treeData.guid);
      dispatch({ type: YOUR_TESTS_ACTIONS.UPDATE_FOLDERS_TREE_DATA_ARRAY, payload: treeDataAsArray });
    }
  }, [state.treeData]);

  /**
   * Load initial tests folders data
   */
  const loadInitialFoldersAndTests = async isReload => {
    showLoader();
    try {
      const { guid, testBindings } = await getRootTests();

      const sequenceMap = convertToSequenceMap(testBindings, 'testId');

      // Fetch root level folders and tests
      let [folders, tests] = await Promise.all([getUserTestFolders(guid), getFolderTests(guid, 1)]);

      folders.sort((a, b) => b.sequence - a.sequence);

      tests = tests || [];
      tests = tests.map(test => ({
        ...test,
        sequence: sequenceMap[test.guid],
      }));

      dispatch({
        type: YOUR_TESTS_ACTIONS.UPDATE_ROOT_LEVEL_FOLDERS_TESTS,
        payload: {
          treeData: {
            guid,
            children: { folders, tests },
            testBindings,
          },
          hasMore: tests.length === MAX_TESTS_TO_LOAD_AT_ONCE,
        },
      });
    } catch (error) {
      toastify.showErrorToast(error?.message);
    } finally {
      hideLoader();
    }
  };

  const loadRootLevelTestsLazy = async pageNumber => {
    const tests = await getFolderTests(state.treeData.guid, pageNumber);

    // Update the state with the new tests
    dispatch({
      type: 'INSERT_AT_END_ROOT_LEVEL_TESTS',
      payload: tests,
    });
  };

  /**
   * Load child folders and tests
   * @param {object} node - Folder node
   */
  const loadChildFoldersAndTests = async node => {
    showLoader();
    try {
      // Fetch child folders and tests
      let [folders, tests] = await Promise.all([getUserTestFolders(node.id), getFolderTests(node.id)]);

      // Sort folders by sequence
      folders = folders.slice().sort((a, b) => b.sequence - a.sequence);

      const sequenceMap = convertToSequenceMap(node.testBindings, 'testId');

      tests = tests || [];
      // Add sequence to tests
      tests = tests.map(test => ({ ...test, sequence: sequenceMap[test.guid] }));

      // Update state with new folders and tests
      dispatch({ type: YOUR_TESTS_ACTIONS.INSERT_FOLDERS_AND_TESTS, payload: { folders, tests, parentId: node.id } });
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  /**
   * Add a new folder
   * @param {string} name - New folder name
   */
  const addNewFolder = async name => {
    showLoader();
    try {
      // Calculate new sequence for the folder
      const maxSequence = Math.max(...state.treeData.children.folders.map(folder => folder.sequence), 1);
      const newSequence = maxSequence + 1;

      // Create new folder data
      const newFolderData = {
        parentId: state.treeData.guid,
        sequence: newSequence,
        title: name.trim(),
      };

      // Save new folder
      const savedFolder = await saveTestFolder(newFolderData);

      // Update state with new folder
      dispatch({ type: YOUR_TESTS_ACTIONS.ADD_NEW_FOLDER, payload: savedFolder });

      toastify.showSuccessToast(intl.formatMessage({ id: 'success.folder.save' }));
    } catch (error) {
      if (error?.status === 409) {
        toastify.showWarningToast(error.message);
      } else {
        toastify.showWarningToast(intl.formatMessage({ id: 'error.FailedToSaveFolder' }));
      }
    } finally {
      hideLoader();
    }
  };

  /**
   * Edit folder name
   * @param {string} name - New folder name
   * @param {object} node - Folder node
   */
  const editFolderName = async (name, node) => {
    showLoader();
    try {
      // Create updated folder data
      const updatedFolderData = {
        guid: node.id,
        parentId: node.parent,
        sequence: node.sequence,
        testBindings: node.testBindings,
        title: name,
      };

      // Update folder
      const updatedFolder = await updateTestFolder(updatedFolderData);
      // Update state with updated folder
      dispatch({ type: YOUR_TESTS_ACTIONS.EDIT_FOLDER_NAME, payload: updatedFolder });
      toastify.showSuccessToast(intl.formatMessage({ id: 'success.folder.update' }));
    } catch (error) {
      if (error.status === 409) {
        toastify.showWarningToast(error.message);
      } else {
        toastify.showWarningToast(intl.formatMessage({ id: 'error.FailedToUpdateFolder' }));
      }
    } finally {
      hideLoader();
    }
  };

  /**
   * Remove the test from the tests array if it is open in the test tab
   * @param {string} testId - Test ID
   * @returns {void}
   */
  const removeTestFromTestTabs = testId => {
    // Remove the test from the tests array if it is open in the test tab
    const testTobeRemoved = tests.find(test => test.testId === testId);
    if (testTobeRemoved) {
      // dispatch action to remove the test from the test tabs
      testTabsDispatch({ type: 'REMOVE_TEST_FROM_TABS', payload: testTobeRemoved });
    }
  };

  /**
   * Delete folder
   * @param {string} folder - folder to be deleted
   */
  const deleteFolder = async folder => {
    showLoader();
    try {
      // Delete folder
      const deletedTestIds = await deleteTestFolder(folder.id);

      // Update state with deleted folder
      dispatch({ type: YOUR_TESTS_ACTIONS.DELETE_FOLDER, payload: { folder } });

      // Remove the test from the tests array if it is open in the test tab
      deletedTestIds.forEach(removeTestFromTestTabs);

      // Show success toast
      toastify.showSuccessToast(intl.formatMessage({ id: 'success.folder.delete' }));
    } catch (error) {
      toastify.showErrorToast(intl.formatMessage({ id: 'error.failedtodeletefolder' }));
    } finally {
      hideLoader();
    }
  };

  /**
   * Delete Test
   * @param {string} node - test to be deleted
   */
  const deleteTest = async node => {
    showLoader();
    try {
      // Delete test
      await deleteTestFromFolder(node.parent, node.id);

      // Update state with deleted test
      dispatch({ type: YOUR_TESTS_ACTIONS.DELETE_TEST, payload: { test: node } });

      // Remove the test from the tests array if it is open in the test tab
      removeTestFromTestTabs(node.id);

      // Show success toast
      toastify.showSuccessToast(intl.formatMessage({ id: 'success.test.deletesuccessfully' }));
    } catch (error) {
      toastify.showErrorToast(intl.formatMessage({ id: 'error.failedtodeletetest' }));
    } finally {
      hideLoader();
    }
  };

  /**
   * Handle Test drag and drop
   * @param {object} dragSource - Drag source node
   * @param {object} dropTarget - Drop target node
   */
  const handleTestDragAndDrop = async (dragSource, dropTarget) => {
    showLoader();
    try {
      // Get source and destination folder IDs
      const sourceFolderId = dragSource.parent;
      const destinationFolderId = dropTarget ? dropTarget.id : state.treeData.guid;
      // Swap test between folders
      const response = await swapTestBetweenFolders(sourceFolderId, destinationFolderId, dragSource.id);

      if (response.status === 'OK' || response.success) {
        // Update state with new test position
        dispatch({
          type: YOUR_TESTS_ACTIONS.SWAP_TEST_BETWEEN_FOLDERS,
          payload: {
            dragSource,
            dropTarget,
          },
        });

        toastify.showSuccessToast(intl.formatMessage({ id: 'success.Testrearranged.successfully' }));
      }
    } catch (error) {
      console.error('Error rearranging test:', error);
      if ([400, 409].includes(error?.status)) {
        toastify.showWarningToast(error.message);
      } else {
        toastify.showErrorToast(intl.formatMessage({ id: 'error.failed.To.rearrangetest' }));
      }
    } finally {
      hideLoader();
    }
  };

  /**
   * Handle folder drag and drop
   * @param {object} newTree - New tree data
   * @param {object} dragSource - Drag source node
   * @param {object} dropTarget - Drop target node
   */
  const handleFolderDragAndDrop = async (newTree, dragSource, dropTarget) => {
    if (isSourceIsParentOfTarget(state.treeDataAsArray, dragSource.id, dropTarget?.id || state.treeData.guid)) {
      toastify.showWarningToast(intl.formatMessage({ id: 'info.dragFolderToItsOwnChildFolders' }));
      return;
    } else {
      showLoader();
      try {
        // Get parent ID
        const parentId = dropTarget ? dropTarget.id : state.treeData.guid;
        // Get folders in target folder
        const foldersInTree = newTree.filter(item => !item.isTest);
        const foldersInTargetFolder = foldersInTree.filter(folder => folder.parent === parentId);

        // Find source folder index and folder
        const sourceFolderIndex = foldersInTargetFolder.findIndex(folder => folder.id === dragSource.id);
        const sourceFolder = foldersInTargetFolder.find(folder => folder.id === dragSource.id);

        // Check for duplicate folder names
        if (foldersInTargetFolder.filter(item => item.text === sourceFolder.text).length > 1) {
          toastify.showWarningToast(
            intl.formatMessage({ id: 'error.folder.alreadyExists' }, { title: dragSource.text })
          );
          return;
        }

        // Calculate new sequence for the folder
        let newSequence;
        if (foldersInTargetFolder.length === 1) {
          newSequence = 1;
        } else if (sourceFolderIndex === 0) {
          newSequence = foldersInTargetFolder[sourceFolderIndex + 1].sequence + 1;
        } else if (sourceFolderIndex === foldersInTargetFolder.length - 1) {
          newSequence = foldersInTargetFolder[sourceFolderIndex - 1].sequence - 1;
        } else {
          const maxSequence = foldersInTargetFolder[sourceFolderIndex - 1]?.sequence;
          const minSequence = foldersInTargetFolder[sourceFolderIndex + 1]?.sequence;
          newSequence = (minSequence + maxSequence) / 2;
        }

        // Create payload for updated folder
        const payload = {
          guid: sourceFolder.id,
          parentId: sourceFolder.parent,
          testBindings: sourceFolder.testBindings,
          sequence: newSequence,
          title: sourceFolder.text,
        };

        // Update folder
        await updateTestFolder(payload);

        // Update state with new folder position
        dispatch({
          type: YOUR_TESTS_ACTIONS.REARRANGE_FOLDER,
          payload: { dragSource, dropTarget, updatedFolder: payload },
        });

        toastify.showSuccessToast(intl.formatMessage({ id: 'success.folder.rearrange' }));
      } catch (error) {
        // Handle 409 error for conflict when collapsing or other conflicts
        if (error.status === 409) {
          toastify.showWarningToast(error.message);
        } else {
          toastify.showErrorToast(intl.formatMessage({ id: 'error.FailedToRearrangeFolder' }));
        }
      } finally {
        hideLoader();
      }
    }
  };

  const contextValue = {
    ...state,
    loadInitialFoldersAndTests,
    loadChildFoldersAndTests,
    loadRootLevelTestsLazy,
    addNewFolder,
    editFolderName,
    deleteFolder,
    deleteTest,
    handleTestDragAndDrop,
    handleFolderDragAndDrop,
  };

  return <YourTestsContext.Provider value={contextValue}>{children}</YourTestsContext.Provider>;
};

export default YourTestsProvider;

export const useYourTestsContext = () => {
  const context = useContext(YourTestsContext);
  if (!context) {
    throw new Error('useYourTestsContext must be used within a YourTestsProvider');
  }

  return context;
};
