import React from 'react';
import DraggableQuestion from './DraggableQuestion';

/**
 * Returns the key for the given node.
 * @param {Object} node - The node to get the key for.
 *
 * @returns {string} The key for the node.
 */

const getKey = node => {
  const keyFuncs = {
    question: node => node.questionGuid,
    node: node => node.nodeGuid,
    book: node => node.bookGuid,
    discipline: node => node.id,
  };

  return keyFuncs[node.type](node);
};

const DraggableNode = ({
  node,
  isOpen,
  onToggle,
  onDataUpdate,
  onBookSelection,
  isClicked,
  lastSelectedQuestion,
  handleAdd,
}) => {
  const handleNodeSelection = e => {
    e.stopPropagation();
    if (node.type === 'book' && onBookSelection) {
      onBookSelection(node);
    }
  };

  const handleCaretClick = (e, isOpen) => {
    e.stopPropagation();
    onToggle();
    if (!isOpen) {
      onDataUpdate && onDataUpdate(node);
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleCaretClick(event); // Toggle isOpen state
    }
  };

  return (
    <div
      key={getKey(node)}
      className={`question-banks-tree-node ${isClicked ? 'clicked' : ''} ${node.type === 'question' ? 'pl-4' : ''}`}
      tabIndex="0"
    >
      {node.droppable && (
        <span
          className="caret-container"
          onClick={e => handleCaretClick(e, isOpen)}
          onKeyDown={e => handleKeyDown(e, isOpen)}
          tabIndex="0"
        >
          <i className={`fa ${isOpen ? 'fa-caret-down' : 'fa-caret-right'}`}></i>
        </span>
      )}
      {node.type === 'question' ? (
        <DraggableQuestion
          key={getKey(node)}
          question={node.question}
          index={node.index}
          selectedQuestionId={lastSelectedQuestion}
          handleAdd={handleAdd}
        />
      ) : (
        <span className="tree-node-text" onClick={handleNodeSelection}>
          {node.text}
        </span>
      )}
    </div>
  );
};

export default DraggableNode;
