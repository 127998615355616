import { findSelectedFolder } from './utils';

const MAX_QUESTIONS_TO_LOAD_AT_ONCE = Number(process.env.REACT_APP_MAX_QUESTIONS_TO_LOAD_AT_ONCE);

/**
 * Reducer function for managing questions state.
 *
 * @param {Object} state - The current state of the questions.
 * @param {Object} action - The action to be performed on the state.
 * @returns {Object} The new state of the questions.
 */
function YourQuestionsReducer(state, action) {
  const { type, payload } = action;

  switch (type) {
    // Update the root folder ID.
    case 'UPDATE_ROOT_FOLDER_ID':
      return { ...state, rootFolderId: payload };

    // Update the initial data
    case 'UPDATE_INITIAL_DATA':
      return { ...state, ...payload };

    // Add a new folder
    case 'ADD_NEW_FOLDER': {
      const { tree } = state;
      const folders = [...tree.folders, payload];
      folders.sort((a, b) => b.sequence - a.sequence);

      return { ...state, tree: { ...tree, folders } };
    }

    // Update the tree data array
    case 'UPDATE_TREE_DATA_ARRAY':
      return { ...state, treeDataAsArray: payload };

    // Insert folders and questions
    case 'INSERT_FOLDERS_AND_QUESTIONS': {
      const { tree } = state;
      const { parentId, folders, questions } = payload;

      const selectedFolder = findSelectedFolder(tree.folders, parentId);
      if (selectedFolder) {
        selectedFolder.children = {
          folders,
          questions,
        };
      }

      return { ...state, tree: Object.assign({}, tree) };
    }

    case 'INSERT_AT_END_ROOT_LEVEL_QUESTIONS': {
      const { tree } = state;

      const questions = payload.filter(question => question);

      tree.questions = [...tree.questions, ...questions];

      return { ...state, tree: Object.assign({}, tree), hasMore: payload.length === MAX_QUESTIONS_TO_LOAD_AT_ONCE };
    }

    // Edit a folder name
    case 'EDIT_FOLDER_NAME': {
      const { tree } = state;
      let selectedFolder = findSelectedFolder(tree.folders, payload.guid);

      if (selectedFolder) {
        selectedFolder.title = payload.title;
      }

      return { ...state, tree: Object.assign({}, tree) };
    }

    // Swap a question between folders
    case 'SWAP_QUESTION_BETWEEN_FOLDERS': {
      const { tree } = state;
      const { dragSource, dropTarget } = payload;

      const questionIsFromRoot = !dragSource.parent;
      const dropAtRootFolder = !dropTarget;

      // Find Source and Destination folders
      const sourceFolder = questionIsFromRoot ? tree : findSelectedFolder(tree.folders, dragSource.parent);
      const destinationFolder = dropAtRootFolder ? tree : findSelectedFolder(tree.folders, dropTarget.id);

      // Find Selected question from source and delete the question from source
      let selectedQuestion;
      if (questionIsFromRoot) {
        selectedQuestion = sourceFolder.questions.find(question => question.guid === dragSource.id);

        sourceFolder.questions = sourceFolder.questions.filter(question => question.guid !== selectedQuestion.guid);
      } else {
        selectedQuestion = sourceFolder.children.questions.find(question => question.guid === dragSource.id);

        sourceFolder.children.questions = sourceFolder.children.questions.filter(
          question => question.guid !== selectedQuestion.guid
        );
      }

      // Add question to destination folder
      if (dropAtRootFolder) {
        destinationFolder.questions = destinationFolder.questions || [];
        destinationFolder.questions = [...destinationFolder.questions, selectedQuestion];
      } else {
        destinationFolder.children = destinationFolder.children || { questions: [], folders: [] };
        destinationFolder.children.questions = [...destinationFolder.children.questions, selectedQuestion];

        // Calculate new sequence for the question and update question binding for destination folder
        const maxSequence = Math.max(...destinationFolder.questionBindings.map(question => question.sequence), 1);
        const newSequence = maxSequence + 1;
        destinationFolder.questionBindings = [
          ...destinationFolder.questionBindings,
          {
            questionId: selectedQuestion.guid,
            sequence: newSequence,
          },
        ];
      }

      return { ...state, tree: Object.assign({}, tree) };
    }

    // Rearrange a folder
    case 'REARRANGE_FOLDER': {
      const { tree } = state;
      const { dragSource, dropTarget, updatedFolder } = payload;

      // Find subfolders at parent folder
      const subFoldersAtParent =
        dragSource.parent === 0 ? tree.folders : findSelectedFolder(tree.folders, dragSource.parent).children.folders;

      // Find Old index of dragged folder
      const oldSourceFolderIndex = subFoldersAtParent.findIndex(folder => folder.guid === dragSource.id);
      // Delete folder from old folder
      const [draggedFolder] = subFoldersAtParent.splice(oldSourceFolderIndex, 1);

      // Update sequence of dragged folder
      draggedFolder.sequence = updatedFolder.sequence;

      // Find Sub folders at destination folder
      let subFoldersAtDestination;
      if (dropTarget) {
        const destFolder = findSelectedFolder(tree.folders, dropTarget.id);
        const children = destFolder.children || {};
        subFoldersAtDestination = children.folders || [];
      } else {
        subFoldersAtDestination = tree.folders;
      }

      // Add dragged folder at destination and update the folders based on sequence
      subFoldersAtDestination.push(draggedFolder);
      subFoldersAtDestination.sort((a, b) => b.sequence - a.sequence);

      return { ...state, tree: Object.assign({}, tree) };
    }

    default:
      return state;
  }
}

export default YourQuestionsReducer;
