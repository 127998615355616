import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Tree } from '@minoru/react-dnd-treeview';
import Placeholder from 'common/components/Placeholder';
import DraggableIcon from 'common/components/DraggableIcon';
import { subscribe, unsubscribe } from 'common/utils/events';
import { useConfirmDialog } from 'common/components/ConfirmationDialog';
import { useManageQuestionsContext } from 'workspace/providers/manage-questions/ManageQuestionsProvider';
import { getNodeIdsToClose } from 'workspace/providers/manage-questions/utils';

const FoldersTreeView = ({ folderToEdit, onNodeEditClick, onFolderDeleteClick }) => {
  const { showConfirmDialog } = useConfirmDialog();
  const intl = useIntl();
  const {
    treeDataAsArray,
    handleFolderDragAndDrop,
    handleQuestionDragAndDrop,
    treeData,
    selectedFolder,
    updateSelectedFolder,
    loadChildFoldersAndQuestions,
    deleteQuestionFromFolder,
  } = useManageQuestionsContext();
  const treeRef = useRef(null);
  const dragSourceIdRef = useRef(null);

  useEffect(() => {
    subscribe('ws:import_folder_successful', collapseAllNodes);

    return () => unsubscribe('ws:import_folder_successful', collapseAllNodes);
  }, [treeData]);

  const collapseAllNodes = () => {
    treeRef.current.closeAll();
  };

  /**
   * Load child nodes for a given node
   *
   * @param {object} node - Node to load child nodes for
   */
  const loadChildNodes = node => {
    loadChildFoldersAndQuestions(node);
  };

  const handleDrop = async (newTree, { dropTarget }) => {
    if (dragSourceIdRef.current.isQuestion) {
      await handleQuestionDragAndDrop(newTree, dragSourceIdRef.current, dropTarget);
    } else {
      await handleFolderDragAndDrop(newTree, dragSourceIdRef.current, dropTarget);
      // closes the accordion of dragged folder and its children
      const draggedFolderWithChildIds = getNodeIdsToClose(treeData, dragSourceIdRef.current.id);
      treeRef.current.close(draggedFolderWithChildIds);
    }
  };

  /**
   * Handles the drag start event for a drag source.
   * @param {Object} dragSource - The drag source object that was dragged.
   */
  const handleDragStart = dragSource => {
    dragSourceIdRef.current = dragSource;
  };

  const handleDeleteClick = node => {
    if (node.isQuestion) {
      showConfirmDialog({
        title: intl.formatMessage({ id: 'ws.modal.delete.confirmation.title' }),
        message: (
          <>
            <i className="fa-solid fa-circle-question"></i>
            &nbsp;
            <FormattedMessage id="ws.modal.delete.question.confirmation.message" />
          </>
        ),
        confirmText: intl.formatMessage({ id: 'message.delete' }),
        cancelText: intl.formatMessage({ id: 'message.cancel' }),
        onConfirm: async () => {
          await deleteQuestionFromFolder(node.parent, node.data.guid);
        },
      });
    } else {
      onFolderDeleteClick(node);
    }
  };

  const handleFolderSelection = node => {
    if (!node.isQuestion) {
      updateSelectedFolder(selectedFolder === node.id ? null : node.id);
    }
  };

  return (
    <div className={`folders-tree-view`}>
      <Tree
        ref={treeRef}
        sort={false}
        tree={treeDataAsArray}
        rootId={treeData?.guid}
        dropTargetOffset={5}
        onDragStart={handleDragStart}
        render={(node, { isOpen, onToggle, handleRef }) => (
          <div className={`ws-folders-tree-node ${selectedFolder === node.id ? 'clicked' : ''} `}>
            <DraggableIcon ref={handleRef} />
            {!node.isQuestion && (
              <span
                className="caret-container"
                onClick={() => {
                  if (!isOpen && !node.isChildrenLoaded) {
                    loadChildNodes(node);
                  }
                  onToggle();
                }}
                tabIndex="0"
              >
                {isOpen ? <i className="fa fa-caret-down"></i> : <i className="fa fa-caret-right"></i>}
              </span>
            )}

            <div className="tree-node-text flex-grow-1" onClick={() => handleFolderSelection(node)}>
              {node.text}
            </div>
            <div className="tree-node-action-buttons-container">
              <button
                className={`action-button ${folderToEdit?.id === node.id ? 'selected' : ''}`}
                onClick={() => onNodeEditClick(node)}
                title={intl.formatMessage({ id: 'message.edit', defaultMessage: 'Edit' })}
              >
                <i className="bi bi-pencil-fill"></i>
              </button>
              <button
                className="action-button"
                onClick={() => handleDeleteClick(node)}
                title={intl.formatMessage({ id: 'message.delete', defaultMessage: 'Delete' })}
                aria-label="Delete"
                disabled={folderToEdit?.id === node.id}
              >
                <i className="bi bi-trash"></i>
              </button>
            </div>
          </div>
        )}
        dragPreviewRender={monitorProps => <div className="custom-drag-preview">{monitorProps.item.text}</div>}
        placeholderRender={(node, { depth }) => <Placeholder node={node} depth={depth} />}
        canDrop={(_currentTree, { dropTarget }) => {
          return dropTarget ? !dropTarget.isQuestion : true;
        }}
        onDrop={handleDrop}
      />
    </div>
  );
};

export default FoldersTreeView;
