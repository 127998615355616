import deepEqual from 'deep-equal';
import { deepClone } from '.';
import QtiService from './qti-converter';
import { DEFAULT_EXTENDED_METADATA, DIFFICULTY_LEVEL_MAP, QUESTION_TYPES } from 'common/constants/question-constants';

export const isValidQuizType = quizType => {
  return quizType && Object.values(QUESTION_TYPES).includes(quizType);
};

/**
 * Constructs a question object from the provided question data.
 *
 * @param {Object} question - The question data to construct the object from.
 * @returns {Object} The constructed question object.
 */
export const constructQuestionObject = ({ guid, qtixml, metadata }) => {
  const { quizType, extendedMetadata } = metadata;

  if (!isValidQuizType(quizType)) {
    throw new Error(`Invalid quiz type: ${quizType}`);
  }

  // Get the QTI model from the QtiService using the qtixml and quiz type.
  const qtiModel = QtiService.getQtiModel(qtixml, quizType);

  // Convert extended metadata to an object.
  const extendedMetadataObject = convertExtendedMetadataToObject(extendedMetadata);

  // Set the isInEditView property of the QTI model to false.
  qtiModel.isInEditView = false;
  qtiModel.extendedMetadata = extendedMetadataObject;

  // Create a deep copy of the QTI model to avoid mutating the original object.
  const masterData = deepClone(qtiModel);

  return {
    guid,
    itemId: guid,
    qtixml,
    quizType,
    qtiModel,
    masterData,
    metadata,
    spaceLine: 0,
  };
};

/**
 * Returns a new question object based on the provided questionTemplate.
 * @param {Object} questionTemplate - The template for the question.
 * @returns {Object} A new question object with the qtiModel and itemId properties.
 */
export const transformQuestionTemplateToQuestion = question => {
  const { qtixml, quizType } = question;

  const itemId = Math.random().toString(36).slice(2);
  const metadata = { quizType };

  // Get the QTI model from the QtiService using the qtixml and quiz type.
  const qtiModel = QtiService.getQtiModel(qtixml, quizType);

  // Convert extended metadata to an object.
  const extendedMetadataObject = convertExtendedMetadataToObject([]);

  qtiModel.isInEditView = true;
  qtiModel.extendedMetadata = extendedMetadataObject;

  // Create a deep copy of the QTI model to avoid mutating the original object.
  const masterData = deepClone(qtiModel);

  return { ...question, itemId, qtiModel, masterData, metadata, spaceLine: 0 };
};

/**
 * Generates a unique ID as a random string.
 *
 * @returns {string} A unique ID as a random string.
 */
const generateUniqueId = () => Math.random().toString(36).slice(2);

/**
 * Updates a question object with a unique item ID.
 *
 * @param {object} data - The question data to update.
 * @param {boolean} isDuplicate - Whether to generate a new unique ID.
 * @returns {object} The updated question object with a unique item ID.
 */
export const updateQuestionWithItemId = (data, isDuplicate) => {
  const clonedData = deepClone(data);
  const itemId = isDuplicate ? generateUniqueId() : data.guid || data.itemId;
  const question = {
    ...clonedData,
    itemId,
  };

  return question;
};

/**
 * Rearranges the questions array by moving the question with the given dragSourceId to the position of the question with the given dropTargetId.
 *
 * @param {Object[]} questions - The array of questions to be rearranged.
 * @param {string} dragSourceId - The guid or itemId of the question to be moved.
 * @param {string} dropTargetId - The guid or itemId of the question that the dragSource question will be moved to.
 *
 * @returns {Object[]} The rearranged questions array.
 */
export const rearrangeQuestions = (questions, dragSourceId, dropTargetId) => {
  // Find the indices of the drag source and drop target
  const dragSourceIndex = questions.findIndex(
    question => question.guid === dragSourceId || question.itemId === dragSourceId
  );
  const dropTargetIndex = questions.findIndex(
    question => question.guid === dropTargetId || question.itemId === dropTargetId
  );

  // Get the drag source item
  const dragSourceItem = { ...questions[dragSourceIndex] };

  // Remove the drag source item from the array
  questions.splice(dragSourceIndex, 1);

  // Insert the drag source item at the drop target index
  questions.splice(dropTargetIndex, 0, dragSourceItem);

  return questions;
};

/**
 * Converts an array of extended metadata objects to a single object.
 *
 * @param {Array<Object>} extendedMetadata - Array of objects with `name` and `value` properties.
 * @returns {Object} - An object with metadata names as keys and values as values.
 */
export const convertExtendedMetadataToObject = extendedMetadata => {
  // Return the default metadata if the input array is empty or null
  if (!extendedMetadata || extendedMetadata.length === 0) return DEFAULT_EXTENDED_METADATA;

  // Use the reduce method to create an object from the array of metadata
  const metadataObject = extendedMetadata.reduce(
    (obj, { name, value }) => ({ ...obj, [name]: value || '' }),
    DEFAULT_EXTENDED_METADATA
  );

  // Handle the question level of difficulty metadata
  if (metadataObject.questionLevelOfDifficulty) {
    // Check if the value is a number and map it to the corresponding difficulty level
    metadataObject.questionLevelOfDifficulty = isNaN(metadataObject.questionLevelOfDifficulty)
      ? metadataObject.questionLevelOfDifficulty
      : DIFFICULTY_LEVEL_MAP[Number(metadataObject.questionLevelOfDifficulty)];
  } else {
    // Fallback to the difficulty metadata if questionLevelOfDifficulty is not present
    metadataObject.questionLevelOfDifficulty = metadataObject.difficulty || '';
  }

  return metadataObject;
};

/**
 * Builds an array of metadata objects from the extended metadata of a question.
 *
 * @param {Object} qstn - The question object.
 * @param {Object} userSettings - The user settings object.
 * @returns {Array<Object>} An array of metadata objects.
 */
const buildQuestionMetadataArray = (qstn, userSettings) =>
  Object.keys(qstn.qtiModel.extendedMetadata).map(key => ({
    name: key,
    value: qstn.qtiModel.extendedMetadata[key],
  }));

/**
 * Checks if a question has been edited by comparing its master data with its QTI model.
 *
 * @param {Object} question - The question object.
 * @returns {boolean} True if the question has been edited, false otherwise.
 */

const isQuestionEdited = question => !deepEqual(question.masterData, question.qtiModel);

/**
 * Builds a question envelope object containing metadata and body.
 *
 * @param {Object} question - The question object.
 * @param {Object} userSettings - The user settings object.
 * @returns {Object} The question envelope object.
 */
export const buildQuestionEnvelop = (question, userSettings) => {
  return {
    metadata: {
      ...question.metadata,
      guid: isQuestionEdited(question) ? null : question.guid,
      extendedMetadata: buildQuestionMetadataArray(question, userSettings),
    },
    body: question.qtixml,
  };
};

/**
 * Builds a question envelope object containing metadata and body.
 *
 * @param {Object} question - The question object.
 * @param {Object} userSettings - The user settings object.
 * @returns {Object} The question envelope object.
 */
export const buildQuestionEnvelopForEdit = (question, userSettings) => {
  return {
    metadata: {
      ...question.metadata,
      guid: question.guid,
      extendedMetadata: buildQuestionMetadataArray(question, userSettings),
    },
    guid: question.guid,
    body: question.qtixml,
  };
};

/**
 * Checks if all questions in a test are in view mode.
 *
 * @param {Array<Question>} questions - The questions to check.
 * @returns {boolean} True if all questions are in view mode, false otherwise.
 */
export const isAllQuestionsAreInViewMode = questions => {
  return (
    questions.length > 0 &&
    !questions.find(item => item.qtiModel?.isInEditView || (item.data && item.data.qtiModel?.isInEditView))
  );
};
