import axios from 'test-builder/api/axios';

const apiUrl = `${process.env.REACT_APP_API_URL}/auth`; // Assuming you have your API URL defined in environment variables

const callLoginEndpoint = async token => {
  const config = {
    headers: {
      AccessToken: token,
      UserId: sessionStorage.getItem('userId'),
      SmsUserId: sessionStorage.getItem('SmsUserId'),
      Accept: 'application/json',
    },
  };

  return axios.get(apiUrl, config);
};

export default callLoginEndpoint;
