import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { toastify } from 'common/components/Toastify';
import { subscribe, unsubscribe } from 'common/utils/events';
import { useYourTestsContext } from 'test-builder/providers/YourTests/YourTestsProvider';
import YourTestsTreeView from './YourTestsTreeView';
import MigratedTests from './MigratedTests';
import AddFolder from '../AddFolder';
import './your-tests.css';

const YourTests = () => {
  const intl = useIntl();
  const { treeDataAsArray, loadInitialFoldersAndTests, addNewFolder, editFolderName } = useYourTestsContext();

  const scrollableDivRef = useRef(null);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [selectedFolder, setSelectedFolder] = useState();

  /**
   * Load initial test folders data and subscribe to 'reload_your_tests' event
   */
  useEffect(() => {
    loadInitialData();

    // Subscribe to the 'reload_your_tests' event to reload data when triggered
    subscribe('reload_your_tests', reloadInitialTests);

    // Return a cleanup function to unsubscribe from the event when the component unmounts
    return () => {
      // Unsubscribe from the 'reload_your_tests' event to prevent memory leaks
      unsubscribe('reload_your_tests', reloadInitialTests);
    };
  }, []);

  const loadInitialData = async () => {
    setIsDataLoading(true);

    // Load initial test folders data when the component mounts
    await loadInitialFoldersAndTests();

    setIsDataLoading(false);
  };

  const reloadInitialTests = () => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop = 0;
    }

    loadInitialFoldersAndTests();
  };

  /**
   * Handle saving a folder
   *
   * @param {string} folderName The folder name to save
   * @param {boolean} isEditing Whether the folder is being edited or added
   * @returns {void}
   */
  const handleSaveFolder = (folderName, isEditing) => {
    const trimmedFolderName = folderName.trim();

    // Check if the folder name is empty
    if (!trimmedFolderName) {
      toastify.showErrorToast(intl.formatMessage({ id: 'error.FolderNameCannotBeEmpty' }));
      return;
    }

    if (trimmedFolderName.trim().toLowerCase() === 'migrated tests') {
      toastify.showErrorToast(intl.formatMessage({ id: 'error.Folder.name.MigratedTests' }));
      return;
    }

    try {
      // Determine whether to edit an existing folder or add a new one
      if (isEditing) {
        // Edit the folder name
        editFolderName(trimmedFolderName, selectedFolder);
        // clear folder selection
        setSelectedFolder(null);
      } else {
        // Add a new folder
        addNewFolder(trimmedFolderName);
      }
    } catch (error) {
      // Handle errors when saving the folder
      if (error?.status === 409) {
        toastify.showErrorToast(error.message);
      } else {
        toastify.showErrorToast(
          intl.formatMessage({ id: isEditing ? 'error.folder.update.failed' : 'error.failedtosavefolder' })
        );
      }
    }
  };

  /**
   * Handle closing the add folder dialog
   *
   * @returns {void}
   */
  const handleAddFolderClose = () => {
    setSelectedFolder(null);
  };

  /**
   * Handle editing a folder name
   *
   * @param {object} node The folder node being edited
   * @returns {void}
   */
  const handleEditFolderName = node => {
    setSelectedFolder(node);
  };

  return (
    <div className="your-tests-container p-2 flex-column">
      <AddFolder name={selectedFolder?.text || ''} onFolderAdd={handleSaveFolder} onClose={handleAddFolderClose} />
      <div ref={scrollableDivRef} className="your-tests-folders-container flex-grow-1">
        <MigratedTests />
        {!isDataLoading && treeDataAsArray.length ? (
          <YourTestsTreeView selectedFolder={selectedFolder} onEditFolderNameClick={handleEditFolderName} />
        ) : null}
      </div>
    </div>
  );
};

export default YourTests;
