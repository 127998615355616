import { useCallback, useEffect, useReducer } from 'react';
import { useIntl } from 'react-intl';

import { useLoader } from 'common/providers/LoaderProvider';
import { toastify } from 'common/components/Toastify';
import { isSourceIsParentOfTarget } from 'common/utils';
import { constructQuestionObject } from 'common/utils/questions-utils';
import {
  getChildQuestionFolders,
  getUserQuestions,
  saveUserQuestionFolder,
  swapQuestionBetweenFolders,
  updateUserQuestionFolders,
} from 'test-builder/services/userfolder.service';
import YourQuestionsReducer from './YourQuestionsReducer';
import YourQuestionsContext from './YourQuestionsContext';
import {
  fetchQuestions,
  fetchRootFolderGuid,
  fetchUserRootLevelFolders,
  findSelectedFolder,
  generateTreeDataFromTree,
} from './utils';

const MAX_QUESTIONS_TO_LOAD_AT_ONCE = Number(process.env.REACT_APP_MAX_QUESTIONS_TO_LOAD_AT_ONCE);

const initialState = {
  rootFolderId: null,
  tree: null, // data in tree structure
  treeDataAsArray: [], // flat array representation of data which TreeView component needs
  hasMore: false,
};

const YourQuestionsProvider = ({ children }) => {
  const intl = useIntl();
  const [state, dispatch] = useReducer(YourQuestionsReducer, initialState);
  const { showLoader, hideLoader } = useLoader();

  // Update tree data array when tree changes
  useEffect(() => {
    if (state?.tree) {
      const treeDataAsArray = generateTreeDataFromTree(state.tree);
      dispatch({ type: 'UPDATE_TREE_DATA_ARRAY', payload: treeDataAsArray });
    }
  }, [state.tree]);

  /**
   * Update root folder ID
   * @param {string} id - New root folder ID
   */
  const updateRootFolderID = useCallback(id => {
    dispatch({ type: 'UPDATE_ROOT_FOLDER_ID', payload: id });
  }, []);

  /**
   * Load initial question folders data
   */
  const loadInitialQuestionFoldersData = async () => {
    showLoader();
    try {
      // Fetch root folder details
      const rootFolderDetails = await fetchRootFolderGuid();
      // Fetch root level questions and folders
      const [questions, folders] = await Promise.all([
        fetchQuestions(rootFolderDetails.guid, 1),
        fetchUserRootLevelFolders(),
      ]);

      // Create tree data
      const tree = { folders, questions: questions.filter(question => question) };

      // Update state with initial data
      dispatch({
        type: 'UPDATE_INITIAL_DATA',
        payload: {
          tree,
          rootFolderId: rootFolderDetails.guid,
          hasMore: questions.length === MAX_QUESTIONS_TO_LOAD_AT_ONCE,
        },
      });
    } catch (error) {
      toastify.showErrorToast(intl.formatMessage({ id: 'error.FailedToGetRootFoldersOrQuestions' }));
    } finally {
      hideLoader();
    }
  };

  const loadRootLevelQuestionsLazy = async pageNumber => {
    let questions = await fetchQuestions(state.rootFolderId, pageNumber);
    // Update state with new questions
    dispatch({
      type: 'INSERT_AT_END_ROOT_LEVEL_QUESTIONS',
      payload: questions,
    });
  };

  /**
   * Add a new folder
   * @param {string} name - New folder name
   */
  const addNewFolder = async name => {
    showLoader();
    try {
      // Calculate new sequence for the folder
      const maxSequence = Math.max(...state.tree.folders.map(folder => folder.sequence), 1);
      const newSequence = maxSequence + 1;

      // Create new folder data
      const newFolderData = {
        parentId: state.rootFolderId,
        sequence: newSequence,
        title: name,
      };

      // Save new folder
      const savedFolder = await saveUserQuestionFolder(newFolderData);

      // Update state with new folder
      dispatch({ type: 'ADD_NEW_FOLDER', payload: savedFolder });

      toastify.showSuccessToast(intl.formatMessage({ id: 'success.folder.save' }));
    } catch (error) {
      if (error?.status === 409) {
        toastify.showWarningToast(error.message);
      } else {
        toastify.showWarningToast(intl.formatMessage({ id: 'error.FailedToSaveFolder' }));
      }
    } finally {
      hideLoader();
    }
  };

  /**
   * Edit folder name
   * @param {string} name - New folder name
   * @param {object} node - Folder node
   */
  const editFolderName = async (name, node) => {
    showLoader();
    try {
      // Find selected folder
      const selectedFolder = findSelectedFolder(state.tree.folders, node.id);

      // Create updated folder data
      const updatedFolderData = {
        guid: selectedFolder.guid,
        parentId: selectedFolder.parentId,
        sequence: selectedFolder.sequence,
        questionBindings: selectedFolder.questionBindings,
        title: name,
      };

      // Update folder
      const updatedFolder = await updateUserQuestionFolders(updatedFolderData);

      // Update state with updated folder
      dispatch({ type: 'EDIT_FOLDER_NAME', payload: updatedFolder });

      toastify.showSuccessToast(intl.formatMessage({ id: 'success.folder.update' }));
    } catch (error) {
      if (error.status === 409) {
        toastify.showWarningToast(error.message);
      } else {
        toastify.showWarningToast(intl.formatMessage({ id: 'error.FailedToUpdateFolder' }));
      }
    } finally {
      hideLoader();
    }
  };

  /**
   * Load child folders and questions
   * @param {object} node - Folder node
   */
  const loadChildFoldersAndQuestions = async node => {
    showLoader();
    try {
      // Fetch child folders and questions
      let [folders, questions] = await Promise.all([getChildQuestionFolders(node.id), getUserQuestions(node.id)]);

      // Sort folders by sequence
      folders = folders.slice().sort((a, b) => b.sequence - a.sequence);

      questions = questions || [];
      // Add QTI model to questions
      questions = questions
        .map(question => {
          try {
            return constructQuestionObject(question);
          } catch (error) {
            return null;
          }
        })
        .filter(question => question);

      // Update state with new folders and questions
      dispatch({ type: 'INSERT_FOLDERS_AND_QUESTIONS', payload: { folders, questions, parentId: node.id } });
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  /**
   * Handle question drag and drop
   * @param {object} dragSource - Drag source node
   * @param {object} dropTarget - Drop target node
   */
  const handleQuestionDragAndDrop = async (dragSource, dropTarget) => {
    showLoader();

    try {
      // Get source and destination folder IDs
      const sourceFolderId = dragSource.parent || state.rootFolderId;
      const destinationFolderId = dropTarget ? dropTarget.id : state.rootFolderId;
      // Swap question between folders
      const response = await swapQuestionBetweenFolders(sourceFolderId, destinationFolderId, dragSource.id);

      if (response.status === 'OK' || response.success) {
        // Update state with new question position
        dispatch({
          type: 'SWAP_QUESTION_BETWEEN_FOLDERS',
          payload: {
            dragSource,
            dropTarget,
          },
        });

        toastify.showSuccessToast(intl.formatMessage({ id: 'success.questionMovedSuccessfully' }));
      }
    } catch (error) {
      toastify.showErrorToast(intl.formatMessage({ id: 'error.failedToMoveQuestion' }));
      console.error('Error swapping question between folders:', error);
    } finally {
      hideLoader();
    }
  };

  /**
   * Handle folder drag and drop
   * @param {object} newTree - New tree data
   * @param {object} dragSource - Drag source node
   * @param {object} dropTarget - Drop target node
   */
  const handleFolderDragAndDrop = async (newTree, dragSource, dropTarget) => {
    if (isSourceIsParentOfTarget(state.treeDataAsArray, dragSource.id, dropTarget?.id || 0)) {
      toastify.showWarningToast(intl.formatMessage({ id: 'info.dragFolderToItsOwnChildFolders' }));
      return;
    } else {
      showLoader();
      try {
        // Get parent ID
        const parentId = dropTarget ? dropTarget.id : 0;
        // Get folders in target folder
        const foldersInTree = newTree.filter(item => !item.isQuestion);
        const foldersInTargetFolder = foldersInTree.filter(folder => folder.parent === parentId);

        // Find source folder index and folder
        const sourceFolderIndex = foldersInTargetFolder.findIndex(folder => folder.id === dragSource.id);
        const sourceFolder = foldersInTargetFolder.find(folder => folder.id === dragSource.id);

        // Check for duplicate folder names
        if (foldersInTargetFolder.filter(item => item.text === sourceFolder.text).length > 1) {
          toastify.showWarningToast(
            intl.formatMessage({ id: 'error.folder.alreadyExists' }, { title: dragSource.text })
          );
          return;
        }

        // Calculate new sequence for the folder
        let newSequence;
        if (foldersInTargetFolder.length === 1) {
          newSequence = 1;
        } else if (sourceFolderIndex === 0) {
          newSequence = foldersInTargetFolder[sourceFolderIndex + 1].sequence + 1;
        } else if (sourceFolderIndex === foldersInTargetFolder.length - 1) {
          newSequence = foldersInTargetFolder[sourceFolderIndex - 1].sequence - 1;
        } else {
          const maxSequence = foldersInTargetFolder[sourceFolderIndex - 1]?.sequence;
          const minSequence = foldersInTargetFolder[sourceFolderIndex + 1]?.sequence;
          newSequence = (minSequence + maxSequence) / 2;
        }

        // Create payload for updated folder
        const payload = {
          guid: sourceFolder.id,
          parentId: sourceFolder.parent || state.rootFolderId,
          questionBindings: sourceFolder.questionBindings,
          sequence: newSequence,
          title: sourceFolder.text,
        };

        // Update folder
        await updateUserQuestionFolders(payload);

        // Update state with new folder position
        dispatch({ type: 'REARRANGE_FOLDER', payload: { dragSource, dropTarget, updatedFolder: payload } });

        toastify.showSuccessToast(intl.formatMessage({ id: 'success.folder.rearrange' }));
      } catch (error) {
        // Handle 409 error for conflict when collapsing or other conflicts
        if (error.status === 409) {
          toastify.showWarningToast(error.message);
        } else {
          toastify.showErrorToast(intl.formatMessage({ id: 'error.FailedToRearrangeFolder' }));
        }
      } finally {
        hideLoader();
      }
    }
  };

  return (
    <YourQuestionsContext.Provider
      value={{
        ...state,

        updateRootFolderID,
        loadInitialQuestionFoldersData,
        loadRootLevelQuestionsLazy,
        addNewFolder,
        editFolderName,
        loadChildFoldersAndQuestions,
        handleQuestionDragAndDrop,
        handleFolderDragAndDrop,
      }}
    >
      {children}
    </YourQuestionsContext.Provider>
  );
};

export default YourQuestionsProvider;
