import axios from 'test-builder/api/axios';

const url = process.env.REACT_APP_API_URL;

export const getAllDisciplines = async () => {
  return axios.get(`${url}/disciplines`);
};

export const getUserDisciplines = async () => {
  return axios.get(`${url}/settings/disciplines`);
};

export const saveUserDiscipline = async disciplines => {
  return axios.post(`${url}/settings/disciplines`, disciplines);
};
