import { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import ResourceTab from '../../components/ResourceTab';
import TestTabs from '../../components/TestTabs/TestTabs';
import TestCreate from '../../components/TestCreate';
import { subscribe, unsubscribe } from '../../../common/utils/events';
import './HomePage.css';

const HomePage = () => {
  const testContainerRef = useRef();

  const scrollToTop = () => {
    testContainerRef.current.scrollTo(0, 0);
  };

  useEffect(() => {
    subscribe('active_test_changed', scrollToTop);

    return () => {
      unsubscribe('active_test_changed', scrollToTop);
    };
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-6 panel panel-left">
          <ResourceTab />
          <div className="container-resource flex-grow-1">
            <Outlet />
          </div>
        </div>
        <div className="col-6 panel panel-right">
          <TestTabs />
          <div ref={testContainerRef} className="container-test">
            <TestCreate />
          </div>
        </div>
      </div>
    </>
  );
};
export default HomePage;
