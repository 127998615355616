import axios from 'test-builder/api/axios';

const url = process.env.REACT_APP_API_URL;
const PAGE_COUNT = Number(process.env.REACT_APP_MAX_TESTS_TO_LOAD_AT_ONCE);

export const saveMyQuestions = questionEnvelops => {
  return axios.post(`${url}/my/questions`, questionEnvelops);
};

export const saveMyTest = (test, folderId) => {
  if (!folderId) folderId = 'null';
  return axios.post(`${url}/my/folders/${folderId}/tests`, test);
};

export const getPrintsettings = async () => {
  return axios.get(`${url}/settings/printsettings`);
};

export const savePrintsettings = data => {
  return axios.post(`${url}/settings/printsettings`, data);
};

export const getFolderTests = (folderId, pageNumber) => {
  let apiUrl = `${url}/my/folders/${folderId}/tests`;

  if (pageNumber) {
    apiUrl = `${apiUrl}?pageNumber=${pageNumber}&pageCount=${PAGE_COUNT}`;
  }

  return axios.get(apiUrl);
};

export const getRootTests = () => {
  return axios.get(`${url}/my/testroot`);
};

export const getTestQuestions = testId => {
  return axios.get(`${url}/test/${testId}/questions`);
};

export const getPublisherTestsByBookId = bookId => {
  return axios.get(`${url}/books/${bookId}/tests`);
};

/**
 * Exports a test in the specified format with the given options.
 *
 * @param {string} testId - The ID of the test to export.
 * @param {string} format - The format to export the test in.
 * @param {Object} options - An object containing additional options for the export.
 *
 * @returns {Promise<Blob>} A promise that resolves with the exported test data as a blob.
 */
export const exportTest = async (testId, format, options) => {
  let data = '';
  for (const key in options) {
    data += `${key}=${options[key]}$`;
  }
  data = data.slice(0, -1);

  const base64Data = btoa(data);
  const downloadURL = `${url}/tests/${testId}/download/${format}?data=${base64Data}`;

  return axios.get(downloadURL, { responseType: 'blob' });
};

export const fetchMyTests = (testId, data) => {
  if (!testId) testId = 'null';
  return axios.post(`${url}/my/tests/${testId}/versions`, data);
};
