import { useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Spinner } from 'react-bootstrap';
import { Tree } from '@minoru/react-dnd-treeview';
import deepEqual from 'deep-equal';
import Placeholder from 'common/components/Placeholder';
import DraggableIcon from 'common/components/DraggableIcon';
import { useConfirmDialog } from 'common/components/ConfirmationDialog';
import { subscribe, unsubscribe } from 'common/utils/events';
import useInfiniteScroll from 'common/hooks/useInfiniteScroll';
import { useYourTestsContext } from 'test-builder/providers/YourTests/YourTestsProvider';
import { useAppContext } from 'test-builder/context/AppContext';
import { getNodeIdsToClose } from 'test-builder/providers/YourTests/utils';

const isTestDraggingAllowed = (dragSource, dropTarget, rootId) => {
  // test should not be dragged on to another test
  if (dropTarget?.isTest) return false;

  // test should not be dragged on to its own
  if (dragSource.id === dropTarget?.id) return false;

  // test should not be dragged on to the same folder
  if (dropTarget ? dragSource.parent === dropTarget.id : dragSource.parent === rootId) return false;

  return true;
};

const YourTestsTreeView = ({ selectedFolder, onEditFolderNameClick }) => {
  const intl = useIntl();
  const { showConfirmDialog } = useConfirmDialog();
  const { selectedTest, dispatchEvent } = useAppContext();

  const {
    treeData,
    treeDataAsArray,
    hasMore,
    loadChildFoldersAndTests,
    loadRootLevelTestsLazy,
    deleteFolder,
    deleteTest,
    handleTestDragAndDrop,
    handleFolderDragAndDrop,
  } = useYourTestsContext();

  const treeRef = useRef(null);

  const fetchMore = async page => {
    await loadRootLevelTestsLazy(page);
  };

  const { loaderRef, reset } = useInfiniteScroll(fetchMore, hasMore);

  useEffect(() => {
    // Subscribe to the 'reload_your_tests' event to collapse all nodes when triggered
    subscribe('reload_your_tests', collapseAllNodes);

    return () => {
      // Unsubscribe from the 'reload_your_tests' event to prevent memory leaks
      unsubscribe('reload_your_tests', collapseAllNodes);
    };
  }, [treeData]);

  const collapseAllNodes = () => {
    treeRef.current.closeAll();

    // reset infinite scroll
    reset();
  };

  /**
   * Handles the drop event for a drag-and-drop operation.
   *
   * @param {object} newTree - The new tree structure after the drop.
   * @param {object} dragSource - The source item being dragged.
   * @param {object} dropTarget - The target item where the drag source is being dropped.
   */
  const handleDrop = async (newTree, { dragSource, dropTarget }) => {
    // Handle test drag-and-drop
    if (dragSource.isTest) {
      if (isTestDraggingAllowed(dragSource, dropTarget, treeData.guid)) {
        handleTestDragAndDrop(dragSource, dropTarget);
      }
    } else {
      // Handle folder drag-and-drop

      // Ignore when folder is dropped at same position
      if (dragSource?.id === dropTarget?.id) {
        return;
      }

      // Check if folder is being reordered within the same parent
      if (dragSource?.parent === dropTarget?.id || (!dropTarget && dragSource?.parent === treeData.guid)) {
        const dropId = dropTarget ? dropTarget.id : treeData.guid;
        const newOrder = newTree.filter(item => item.parent === dropId);
        const oldOrder = treeDataAsArray.filter(item => item.parent === dropId);
        if (deepEqual(newOrder, oldOrder)) {
          return null; // no change, do nothing
        }
      }

      // Handle folder drag-and-drop
      await handleFolderDragAndDrop(newTree, dragSource, dropTarget);

      // closes the accordion of dragged folder and its children
      const draggedFolderWithChildIds = getNodeIdsToClose(treeData.children.folders, dragSource.id);
      treeRef.current.close(draggedFolderWithChildIds);
    }
  };

  /**
   * Load child nodes for a given node
   *
   * @param {object} node - Node to load child nodes for
   */
  const loadChildNodes = node => {
    loadChildFoldersAndTests(node);
  };

  /**
   * Handles the deletion of a folder by displaying a confirmation dialog.
   *
   * @param {Object} node - The node representing the folder to be deleted.
   */
  const handleDeleteFolderClick = node => {
    showConfirmDialog({
      title: intl.formatMessage({ id: 'tb.modal.delete.folder.confirmation.title' }),
      message: (
        <>
          <i className="fa-solid fa-circle-question"></i>
          &nbsp;
          <FormattedMessage id="tb.modal.delete.folder.confirmation.message" />
        </>
      ),
      confirmText: intl.formatMessage({ id: 'message.delete' }),
      cancelText: intl.formatMessage({ id: 'message.cancel' }),
      onConfirm: () => deleteFolder(node),
    });
  };

  /**
   * Dispatches an event to handle the editing of a test.
   *
   * @param {Object} node - The node representing the test to be edited.
   */
  const handleTestEditClick = node => {
    dispatchEvent('HANDLE_VIEW_OR_EDIT_TEST_CLICK', node);
  };

  /**
   * Handles the deletion of a test by displaying a confirmation dialog.
   *
   * @param {Object} node - The node representing the test to be deleted.
   */
  const handleDeleteTestClick = node => {
    showConfirmDialog({
      title: intl.formatMessage({ id: 'tb.modal.delete.test.confirmation.title' }),
      message: (
        <>
          <i className="fa-solid fa-circle-question"></i>
          &nbsp; <FormattedMessage id="tb.modal.delete.test.confirmation.message" />
        </>
      ),
      confirmText: intl.formatMessage({ id: 'message.delete' }),
      cancelText: intl.formatMessage({ id: 'message.cancel' }),
      onConfirm: () => deleteTest(node),
    });
  };

  return (
    <div className="your-tests-treeview treeview" style={{ marginBottom: '-47px' }}>
      <Tree
        ref={treeRef}
        tree={treeDataAsArray}
        sort={false}
        rootId={treeData?.guid}
        canDrag={() => true}
        canDrop={() => true}
        dropTargetOffset={5}
        onDrop={handleDrop}
        dragPreviewRender={monitorProps => <div className="custom-drag-preview">{monitorProps.item.text}</div>}
        placeholderRender={(node, { depth }) => <Placeholder node={node} depth={depth} />}
        render={(node, { isOpen, onToggle, handleRef }) => (
          <div node={node} className="your-tests-tree-node">
            <DraggableIcon ref={handleRef} />
            {!node.isTest && (
              <span
                className="caret-container"
                onClick={() => {
                  if (!isOpen && !node.isChildrenLoaded) {
                    loadChildNodes(node);
                  }
                  onToggle();
                }}
                tabIndex="0"
              >
                <i className={`fa ${isOpen ? 'fa-caret-down' : 'fa-caret-right'}`}></i>
              </span>
            )}

            <div className="tree-node-text flex-grow-1">{node.text}</div>

            <div className="tree-node-action-buttons-container">
              {node.droppable && (
                <button
                  className={`action-button ${selectedFolder?.id === node.id ? 'selected' : ''}`}
                  onClick={() => onEditFolderNameClick(node)}
                  title={intl.formatMessage({ id: 'message.edit', defaultMessage: 'Edit' })}
                  aria-label="Edit"
                >
                  <i className="bi bi-pencil-fill"></i>
                </button>
              )}
              {!node.droppable && (
                <button
                  className={`action-button ${selectedTest?.testId === node.id ? 'highlight' : ''}`}
                  onClick={() => handleTestEditClick(node)}
                  title={intl.formatMessage({ id: 'message.edit', defaultMessage: 'Edit' })}
                  aria-label="Edit"
                >
                  <i className="bi bi-pencil-fill"></i>
                </button>
              )}
              <button
                className="action-button"
                onClick={() => (!node.droppable ? handleDeleteTestClick(node) : handleDeleteFolderClick(node))}
                title={intl.formatMessage({ id: 'message.delete', defaultMessage: 'Delete' })}
                aria-label="Delete"
              >
                <i className="bi bi-trash"></i>
              </button>
            </div>
          </div>
        )}
      />

      {hasMore && (
        <div ref={loaderRef} className="lazy-load-spinner">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default YourTestsTreeView;
