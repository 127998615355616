import { findSelectedFolder } from './utils';

const MAX_TESTS_TO_LOAD_AT_ONCE = Number(process.env.REACT_APP_MAX_TESTS_TO_LOAD_AT_ONCE);

export const YOUR_TESTS_ACTIONS = {
  UPDATE_ROOT_LEVEL_FOLDERS_TESTS: 'UPDATE_ROOT_LEVEL_FOLDERS_TESTS',
  UPDATE_FOLDERS_TREE_DATA_ARRAY: 'UPDATE_FOLDERS_TREE_DATA_ARRAY',
  INSERT_FOLDERS_AND_TESTS: 'INSERT_FOLDERS_AND_TESTS',
  INSERT_AT_END_ROOT_LEVEL_TESTS: 'INSERT_AT_END_ROOT_LEVEL_TESTS',
  ADD_NEW_FOLDER: 'ADD_NEW_FOLDER',
  EDIT_FOLDER_NAME: 'EDIT_FOLDER_NAME',
  DELETE_FOLDER: 'DELETE_FOLDER',
  DELETE_TEST: 'DELETE_TEST',
  SWAP_TEST_BETWEEN_FOLDERS: 'SWAP_TEST_BETWEEN_FOLDERS',
  REARRANGE_FOLDER: 'REARRANGE_FOLDER',
};

function YourTestsReducer(state, action) {
  const { treeData } = state;
  const { type, payload } = action;

  switch (type) {
    case YOUR_TESTS_ACTIONS.UPDATE_ROOT_LEVEL_FOLDERS_TESTS: {
      return { ...state, ...payload };
    }

    case YOUR_TESTS_ACTIONS.UPDATE_FOLDERS_TREE_DATA_ARRAY: {
      return { ...state, treeDataAsArray: payload };
    }

    case YOUR_TESTS_ACTIONS.INSERT_FOLDERS_AND_TESTS: {
      const { parentId, folders, tests } = payload;

      const selectedFolder = findSelectedFolder(treeData.children.folders, parentId);
      if (selectedFolder) {
        selectedFolder.children = {
          folders,
          tests,
        };
        selectedFolder.isChildrenLoaded = true;
      }

      return { ...state, treeData: Object.assign({}, treeData) };
    }

    case YOUR_TESTS_ACTIONS.INSERT_AT_END_ROOT_LEVEL_TESTS: {
      treeData.children.tests = [...treeData.children.tests, ...payload];

      return { ...state, treeData: Object.assign({}, treeData), hasMore: payload.length === MAX_TESTS_TO_LOAD_AT_ONCE };
    }

    case YOUR_TESTS_ACTIONS.ADD_NEW_FOLDER: {
      treeData.children = treeData.children || { folders: [], tests: [] };

      treeData.children.folders = [...treeData.children.folders, payload];

      treeData.children.folders.sort((a, b) => b.sequence - a.sequence);

      return { ...state, treeData: Object.assign({}, treeData) };
    }

    case YOUR_TESTS_ACTIONS.EDIT_FOLDER_NAME: {
      const selectedFolder = findSelectedFolder(treeData.children.folders, payload.guid);
      if (selectedFolder) {
        selectedFolder.title = payload.title;
      }

      return { ...state, treeData: Object.assign({}, treeData) };
    }

    case YOUR_TESTS_ACTIONS.DELETE_FOLDER: {
      const { folder } = payload;
      const currentFolder = findSelectedFolder(treeData.children.folders, folder.id);

      if (currentFolder) {
        const parentFolder = findSelectedFolder(treeData.children.folders, currentFolder.parentId);

        // Remove the folder from its parent's children or from the root if it has no parent
        if (parentFolder) {
          parentFolder.children.folders = parentFolder.children.folders.filter(f => f.guid !== folder.id);
        } else {
          treeData.children.folders = treeData.children.folders.filter(f => f.guid !== folder.id);
        }

        // Check if the deleted folder is the selected one and update the selectedFolder accordingly
        const selectedFolder = currentFolder.guid === state.selectedFolder ? null : state.selectedFolder;

        return {
          ...state,
          treeData: Object.assign({}, treeData),
          selectedFolder,
        };
      }

      return state; // Return original state if no folder is found
    }

    case YOUR_TESTS_ACTIONS.DELETE_TEST: {
      const { test } = payload;
      const currentFolder = findSelectedFolder(treeData.children.folders, test.parent);

      if (currentFolder) {
        currentFolder.children.tests = currentFolder.children.tests.filter(t => t.guid !== test.id);
      } else {
        treeData.children.tests = treeData.children.tests.filter(t => t.guid !== test.id);
      }

      return { ...state, treeData: Object.assign({}, treeData) };
    }

    case YOUR_TESTS_ACTIONS.SWAP_TEST_BETWEEN_FOLDERS: {
      const { dragSource, dropTarget } = payload;

      const testIsFromRoot = dragSource.parent === treeData.guid;
      const dropAtRootFolder = !dropTarget;

      // Find Source and Destination folders
      const sourceFolder = testIsFromRoot ? treeData : findSelectedFolder(treeData.children.folders, dragSource.parent);
      const destinationFolder = dropAtRootFolder
        ? treeData
        : findSelectedFolder(treeData.children.folders, dropTarget.id);

      // Find Selected test from source and delete the test from source
      let draggedTest;
      if (testIsFromRoot) {
        draggedTest = sourceFolder.children.tests.find(test => test.guid === dragSource.id);

        sourceFolder.children.tests = sourceFolder.children.tests.filter(test => test.guid !== draggedTest.guid);
      } else {
        draggedTest = sourceFolder.children.tests.find(test => test.guid === dragSource.id);

        sourceFolder.children.tests = sourceFolder.children.tests.filter(test => test.guid !== draggedTest.guid);
      }

      // Add test to destination folder
      if (dropAtRootFolder) {
        destinationFolder.children.tests = destinationFolder.children.tests || [];
        destinationFolder.children.tests = [...destinationFolder.children.tests, draggedTest];
      } else {
        destinationFolder.children = destinationFolder.children || { tests: [], folders: [] };
        destinationFolder.children.tests = [...destinationFolder.children.tests, draggedTest];

        // Calculate new sequence for the test and update test binding for destination folder
        const maxSequence = Math.max(...destinationFolder.testBindings.map(test => test.sequence), 1);
        const newSequence = maxSequence + 1;
        destinationFolder.testBindings = [
          ...destinationFolder.testBindings,
          {
            testId: draggedTest.guid,
            sequence: newSequence,
          },
        ];
      }

      return { ...state, treeData: Object.assign({}, treeData) };
    }

    case YOUR_TESTS_ACTIONS.REARRANGE_FOLDER: {
      const { dragSource, dropTarget, updatedFolder } = payload;

      // Find subfolders at parent folder
      const subFoldersAtParent =
        dragSource.parent === treeData.guid
          ? treeData.children.folders
          : findSelectedFolder(treeData.children.folders, dragSource.parent).children.folders;

      // Find Old index of dragged folder
      const oldSourceFolderIndex = subFoldersAtParent.findIndex(folder => folder.guid === dragSource.id);
      // Delete folder from old folder
      const [draggedFolder] = subFoldersAtParent.splice(oldSourceFolderIndex, 1);

      // Update sequence of dragged folder
      draggedFolder.sequence = updatedFolder.sequence;

      // Find Sub folders at destination folder
      let subFoldersAtDestination;
      if (dropTarget) {
        const destFolder = findSelectedFolder(treeData.children.folders, dropTarget.id);
        const children = destFolder.children || {};
        subFoldersAtDestination = children.folders || [];
      } else {
        subFoldersAtDestination = treeData.children.folders;
      }

      // Add dragged folder at destination and update the folders based on sequence
      subFoldersAtDestination.push(draggedFolder);
      subFoldersAtDestination.sort((a, b) => b.sequence - a.sequence);

      return { ...state, treeData: Object.assign({}, treeData) };
    }

    default:
      return state;
  }
}

export default YourTestsReducer;
