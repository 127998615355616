/**
 * Converts a tree data structure to a flat array.
 *
 * @param {Object} tree - The tree data structure to convert.
 * @param {String} parent - The parent ID of the current node.
 * @returns {Array} - A flat array of node objects.
 */
export const convertTreeDataToArray = (tree = {}, parent) => {
  const { folders = [], tests = [] } = tree;
  const data = [];

  const foldersData = constructFolders(folders, parent);
  const testsData = constructTests(tests, parent);

  data.push(...foldersData, ...testsData);

  for (let folder of folders) {
    if (folder.children && Object.keys(folder.children).length) {
      data.push(...convertTreeDataToArray(folder.children, folder.guid));
    }
  }

  return data;
};

/**
 * Constructs an array of folder objects from a list of folders.
 *
 * @param {Array} folders - The list of folders to construct.
 * @param {String} parent - The parent ID of the current node.
 * @returns {Array} - An array of folder objects.
 */
const constructFolders = (folders, parent) => {
  if (!folders) return [];

  return folders.map(folder => {
    const { guid: id, sequence, title, testBindings, isChildrenLoaded } = folder;

    return {
      parent,
      id,
      sequence,
      text: title,
      droppable: true,
      isTest: false,
      isChildrenLoaded,
      testBindings,
    };
  });
};

/**
 * Constructs an array of test objects from a list of tests.
 *
 * @param {Array} tests - The list of tests to construct.
 * @param {String} parent - The parent ID of the current node.
 * @returns {Array} - An array of test objects.
 */
const constructTests = (tests, parent) => {
  if (!tests) return [];

  return tests.map(test => {
    return {
      parent,
      id: test.guid,
      text: test.title,
      isTest: true,
      data: test,
      droppable: false,
    };
  });
};

/**
 * Converts a nested array of folders to a flat array.
 *
 * @param {Array} folders - The nested array of folders to convert.
 * @returns {Array} - A flat array of folder objects.
 */
const convertToFlatArray = folders => {
  if (!folders) return [];

  const flatArray = [];

  for (let folder of folders) {
    flatArray.push(folder);

    if (folder.children) {
      flatArray.push(...convertToFlatArray(folder.children.folders));
    }
  }

  return flatArray;
};

/**
 * Finds a folder in a tree structure by its ID.
 *
 * @param {Array} folders - The tree structure to search.
 * @param {String} id - The ID of the folder to find.
 * @returns {Object} - The folder object if found, or null if not found.
 */
export const findSelectedFolder = (folders, id) => {
  // find node in tree stucture
  const flatArray = convertToFlatArray(folders);

  return flatArray.find(folder => folder.guid === id);
};

/**
 * Gets the IDs of all child nodes in a tree structure.
 *
 * @param {Array} folders - The tree structure to get child node IDs from.
 * @returns {Array} - An array of child node IDs.
 */
const getChildNodeIds = (folders = []) => {
  return folders.reduce((acc, folder) => {
    // Add the current folder's GUID
    acc.push(folder.guid);

    // If the folder has children, recursively get their IDs
    if (folder.children) {
      acc.push(...getChildNodeIds(folder.children.folders));
    }

    return acc;
  }, []);
};

/**
 * Gets the IDs of all nodes to close in a tree structure.
 *
 * @param {Array} folders - The tree structure to get node IDs from.
 * @param {String} id - The ID of the current node.
 * @returns {Array} - An array of node IDs to close.
 */
export const getNodeIdsToClose = (folders, id) => {
  const currentFolder = findSelectedFolder(folders, id);
  const childNodes = currentFolder.children ? getChildNodeIds(folders) : [];

  return [id, ...childNodes];
};
