import React from 'react';
import { useDrag } from 'react-dnd';
import { useIntl } from 'react-intl';
import DraggableIcon from 'common/components/DraggableIcon';
import QuestionMapper from '../questions/QuestionMapper';

const DraggableQuestionTemplate = ({ question, handleAdd }) => {
  const intl = useIntl();
  const [, drag, dragPreview] = useDrag({
    type: 'QUESTION_TEMPLATE',
    item: { question },
  });

  return (
    <div className="question-template-item d-flex w-100 align-items-start" ref={dragPreview} tabIndex="0">
      <DraggableIcon ref={drag} />
      <div className="question-area flex-grow-1">
        <QuestionMapper questionNode={question} hideAnswers />
      </div>
      <button
        className="question-templates-plus-icon"
        onClick={() => handleAdd(question)}
        title={intl.formatMessage({ id: 'addQuestionsIcon', defaultMessage: 'Add Questions' })}
      >
        <i className="bi bi-plus-lg darker-icon"></i>
      </button>
    </div>
  );
};

export default DraggableQuestionTemplate;
