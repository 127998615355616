import { useEffect } from 'react';
import Loader from 'common/components/loader/Loader';
import { useAppContext } from '../context/AppContext';
import { useAuth } from '../context/AuthContext';

const AuthWrapper = ({ children }) => {
  const { isAuthFetching } = useAuth();
  const { dispatchEvent } = useAppContext();

  useEffect(() => {
    if (!isAuthFetching) {
      dispatchEvent('FETCH_USER_METADATA');
    }
  }, [isAuthFetching]);

  return isAuthFetching ? (
    <Loader />
  ) : (
    <div className="App">
      <div>{children}</div>
    </div>
  );
};

export default AuthWrapper;
