import React, { useState, useEffect } from 'react';
import { Tree } from '@minoru/react-dnd-treeview';
import { useAppContext } from 'test-builder/context/AppContext';
import { getUserTestFolders } from 'test-builder/services/testfolder.service';
import './FoldersTreeView.css';

function FoldersTreeView({ folders, selectedFolderGuid }) {
  const { selectedFolderWhileSave, testTabsDispatch } = useAppContext();
  const [treeData, setTreeData] = useState([]);

  const fetchChildFolders = async parentNode => {
    try {
      if (!parentNode.children && parentNode.data.guid !== selectedFolderGuid) {
        const childFolders = await getUserTestFolders(parentNode.data.guid);
        const childNodes = [
          ...childFolders.map((childFolder, childIndex) => ({
            id: `${parentNode.id}.${childIndex + 1}`,
            parent: parentNode.id,
            droppable: true,
            text: childFolder.title,
            data: {
              guid: childFolder.guid,
              sequence: childFolder.sequence,
            },
          })),
        ];

        const updatedTreeData = [...treeData];
        const nodeIndex = updatedTreeData.findIndex(n => n.id === parentNode.id);

        const existingChildNodes = updatedTreeData.slice(nodeIndex + 1).filter(node => node.parent === parentNode.id);
        if (existingChildNodes.length === 0) {
          updatedTreeData.splice(nodeIndex + 1, 0, ...childNodes);
          setTreeData(updatedTreeData);
        }
      }
    } catch (error) {
      console.error('Error fetching child question folders:', error);
    }
  };

  useEffect(() => {
    if (folders && folders.length > 0) {
      const updatedTreeData = folders.map((folder, index) => ({
        id: folder.guid,
        parent: 0,
        droppable: folder.parentId ? true : false,
        text: folder.title,
        data: {
          guid: folder.guid,
          sequence: folder.sequence,
        },
      }));
      setTreeData(updatedTreeData);
    }
  }, [folders]);

  const handleNodeClick = node => {
    testTabsDispatch({ type: 'SELECT_FOLDER_WHILE_SAVE', payload: node.data.guid });
  };

  return (
    <div className="treeview" id="modal-treeview">
      <Tree
        sort={false}
        tree={treeData}
        rootId={0}
        render={(node, { isOpen, onToggle }) => (
          <div className={`folders-tree-node ${selectedFolderWhileSave === node.data.guid ? 'clicked' : ''}`}>
            {node.droppable && (
              <span
                className="caret-container"
                onClick={() => {
                  if (!isOpen && (!node.children || node.children.length === 0)) {
                    fetchChildFolders(node);
                  }
                  onToggle();
                }}
              >
                {isOpen ? <i className="fa fa-caret-down"></i> : <i className="fa fa-caret-right"></i>}
              </span>
            )}
            <div
              className="tree-node-text"
              onClick={() => {
                handleNodeClick(node);
              }}
            >
              {node.text}
            </div>
          </div>
        )}
        canDrop={() => false}
        canDrag={() => false}
      />
    </div>
  );
}

export default FoldersTreeView;
