import axios from '../api/axios';

const url = process.env.REACT_APP_API_URL;

export const fetchAllNodesUnderBook = async (bookId, queryParams = {}) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestUrl = `${url}/books/${bookId}/nodes${queryString ? `?${queryString}` : ''}`;

  return axios.get(requestUrl);
};

export const getAllBookNodeSubNodes = async (bookId, nodeId) => {
  return axios.get(`${url}/books/${bookId}/nodes/${nodeId}/nodes`);
};

/**
 * Retrieves a list of books based on the provided discipline and user books.
 *
 * @param {string} discipline - The discipline to filter books by.
 * @param {string[]} userBooks - The list of books associated with the user.
 * @returns {Promise<object[]>} A promise resolving to an array of book objects.
 */
export const getAllBooks = async (discipline, userBooks) => {
  const queryParams = new URLSearchParams({
    discipline: discipline,
    userBooks: userBooks,
  });

  return axios.get(`${url}/books?${queryParams}`);
};

export const importAllBooks = async books => {
  return axios.post(`${url}/ptb/books/import`, books);
};

export const getDisciplineBooks = async discipline => {
  const queryParams = new URLSearchParams({
    discipline: discipline,
  });

  return axios.get(`${url}/books?${queryParams}`);
};

export const saveUserBooks = async books => {
  return axios.post(`${url}/settings/books`, books);
};

export const getUserBooks = async () => {
  return axios.get(`${url}/settings/books`);
};

export const getUserBooksByID = async bookId => {
  return axios.get(`${url}/books/${bookId}`);
};

export const getChaptersByBookID = async (bookId, queryParams = {}) => {
  const queryString = new URLSearchParams(queryParams).toString();
  const requestUrl = `${url}/books/${bookId}/nodes${queryString ? `?${queryString}` : ''}`;

  return axios.get(requestUrl);
};
