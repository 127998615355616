export const METADATA_OPTIONS = Object.freeze({
  QUESTION_LEVEL_OF_DIFFICULTY: 'questionLevelOfDifficulty',
  DIFFICULTY: 'difficulty',
  QUESTION_ID: 'questionId',
  PAGE_REFERENCE: 'pageReference',
  TOPIC: 'topic',
  SKILL: 'skill',
  OBJECTIVE: 'objective',
});

export const ESSAY_QUESTION_SIZE_DATA = [
  {
    id: 'none',
    defaultMessage: 'None',
    value: '0',
  },
  {
    id: 'quarterPage',
    defaultMessage: '1/4 Page',
    value: '10',
  },
  {
    id: 'halfPage',
    defaultMessage: '1/2 Page',
    value: '20',
  },
  {
    id: 'fullPage',
    defaultMessage: '1 Page',
    value: '40',
  },
];
