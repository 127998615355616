// context/AppContext.js
import { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import { useConfirmDialog } from 'common/components/ConfirmationDialog';
import { useLoader } from 'common/providers/LoaderProvider';
import { toastify } from 'common/components/Toastify';
import { constructTest } from 'test-builder/utils/test-utils';
import { getUserProfilesettings } from 'test-builder/services/profile.service';
import TestTabsReducer from './TestTabsReducer';

const MAX_QUESTIONS_COUNT = process.env.REACT_APP_TEST_QUESTIONS_COUNT;
const MAX_ALLOWED_TEST_TABS = process.env.REACT_APP_MAX_ALLOWED_TEST_TABS_COUNT;

const DEFAULT_ADVANCED_SEARCH_SELECTION = Object.freeze({
  questionTypes: [],
  filterBy: '',
  filterByValue: '',
});

const initialTestTabsState = {
  tests: [],
  testsMasterData: [],
  selectedTest: null,
  selectedFolderWhileSave: null,
};

const AppContext = createContext({
  disciplinesData: {},
});

const AppProvider = ({ children }) => {
  const { showConfirmDialog } = useConfirmDialog();
  const intl = useIntl();
  const { showLoader, hideLoader } = useLoader();

  const [{ tests, testsMasterData, selectedTest, selectedFolderWhileSave }, testTabsDispatch] = useReducer(
    TestTabsReducer,
    initialTestTabsState
  );

  const [disciplinesData, setDisciplinesData] = useState({
    allDisciplines: [], // stores all disciplines
    userDisciplines: [], // stores user disciplines
    selectedDisciplines: [], // stores currently selected disciplines
    userBooks: [], // stores user books
    selectedBooks: [], // stores currently selected books
  });

  const [questionBanksData, setQuestionBanksData] = useState({
    advancedSearchSelection: DEFAULT_ADVANCED_SEARCH_SELECTION,
    selectedBookIds: [],
    selectedBookNodes: [],
    simpleSearchText: '',
  });
  const [userMetadata, setUserMetadata] = useState(null);

  useEffect(() => {
    testTabsDispatch({ type: 'INITIALIZE_DEFAULT_TAB' });
  }, []);

  /**
   * Constructs a test from a given node and make it active.
   * @param {Object} node - The node to make the test question from.
   */
  const constructAndActivateTest = async node => {
    if (tests.length >= MAX_ALLOWED_TEST_TABS) {
      const msg = intl.formatMessage({ id: 'warning.moretests' }, { count: MAX_ALLOWED_TEST_TABS });
      toastify.showWarningToast(msg);
      return;
    }

    const testId = node.guid || node.id;

    // Check if the test already exists
    const existingTest = tests.find(
      test => test.testId === testId || (test.metadata.guid === testId && test.testId === undefined)
    );

    if (existingTest) {
      testTabsDispatch({ type: 'ACTIVATE_TEST_TAB', payload: existingTest });
      return;
    }

    try {
      showLoader();
      // Construct the test
      const test = await constructTest(node);
      // Add the test to the list of tests and make it active
      testTabsDispatch({ type: 'ADD_NEW_TEST', payload: test });
    } catch (error) {
      if (error?.status === 409) {
        toastify.showErrorToast(error.message);
      } else {
        toastify.showErrorToast(intl.formatMessage({ id: 'error.Error.fetchingtest' }));
      }
    } finally {
      hideLoader();
    }
  };

  const handleQuestionAdd = question => {
    if (!selectedTest) {
      return;
    }
    if (selectedTest.questions.length >= MAX_QUESTIONS_COUNT) {
      const msg = intl.formatMessage({ id: 'warning.morequestions' }, { count: MAX_QUESTIONS_COUNT });
      toastify.showWarningToast(msg);
      return;
    }
    const test = { ...selectedTest };

    const isDuplicate = question.guid && test.questions?.some(existingNode => existingNode.guid === question.guid);

    if (isDuplicate) {
      showConfirmDialog({
        message: intl.formatMessage({ id: 'ptb.question.duplicate.message' }),
        confirmText: intl.formatMessage({ id: 'message.ok' }),
        cancelText: intl.formatMessage({ id: 'message.cancel' }),
        onConfirm: () => {
          testTabsDispatch({
            type: 'INSERT_QUESTION_AT_END',
            payload: { question, isDuplicate: true },
          });
        },
      });

      return;
    }

    testTabsDispatch({ type: 'INSERT_QUESTION_AT_END', payload: { question } });
  };

  const dispatchEvent = async (actionType, payload) => {
    switch (actionType) {
      case 'HANDLE_VIEW_OR_EDIT_TEST_CLICK':
        constructAndActivateTest(payload);
        return;

      case 'ADD_NEW_QUESTION_TO_TEST':
        handleQuestionAdd(payload);
        return;

      case 'UPDATE_DISCIPLINES_DATA':
        setDisciplinesData({ ...disciplinesData, ...payload });
        return;
      case 'UPDATE_USER_METADATA':
        setUserMetadata(payload);
        return;
      case 'FETCH_USER_METADATA':
        try {
          showLoader();
          const data = await getUserProfilesettings();
          dispatchEvent('UPDATE_USER_METADATA', data);
        } catch (error) {
          toastify.showErrorToast(error?.message);
        } finally {
          hideLoader();
        }
        return;

      case 'UPDATE_ADVANCED_SEARCH_SELECTION':
        setQuestionBanksData(data => ({ ...data, advancedSearchSelection: Object.assign({}, payload) }));
        return;
      case 'CLEAR_ADVANCED_SEARCH_SELECTION':
        setQuestionBanksData(data => ({
          ...data,
          advancedSearchSelection: DEFAULT_ADVANCED_SEARCH_SELECTION,
          selectedBookIds: [],
        }));
        return;

      case 'UPDATE_SELECTED_BOOK_IDS':
        setQuestionBanksData(data => ({ ...data, selectedBookIds: payload }));
        return;
      case 'UPDATE_SELECTED_BOOK_NODES':
        setQuestionBanksData(data => ({ ...data, selectedBookNodes: payload }));
        return;

      case 'UPDATE_SIMPLE_SEARCH_TEXT':
        setQuestionBanksData(data => ({ ...data, simpleSearchText: payload }));
        return;
      case 'CLEAR_SIMPLE_SEARCH_TEXT':
        setQuestionBanksData(data => ({ ...data, simpleSearchText: '', selectedBookIds: [] }));
        return;
      default:
        return;
    }
  };

  const contextValue = {
    tests,
    testsMasterData,
    selectedTest,
    selectedFolderWhileSave,
    testTabsDispatch,

    dispatchEvent,
    disciplinesData,

    questionBanksData,
    setQuestionBanksData,

    userMetadata,
  };

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

const useAppContext = () => useContext(AppContext);

export { AppProvider, useAppContext };
