import React, { createContext, useContext, useState } from 'react';
import Loader from 'common/components/loader/Loader';

const LoaderContext = createContext();

export const LoaderProvider = ({ children }) => {
  const [loader, setLoader] = useState({
    isLoading: false,
    count: 0,
  });

  const contextValue = {
    showLoader: () => {
      setLoader(loader => ({
        count: loader.count + 1,
        isLoading: true,
      }));
    },
    hideLoader: () => {
      setTimeout(() => {
        setLoader(loader => ({
          count: loader.count - 1,
          isLoading: loader.count > 1,
        }));
      }, 100);
    },
  };

  return (
    <LoaderContext.Provider value={contextValue}>
      {loader.isLoading && <Loader />}
      {children}
    </LoaderContext.Provider>
  );
};

export const useLoader = () => {
  const context = useContext(LoaderContext);
  if (!context) {
    throw new Error('useLoader must be used within a LoaderProvider');
  }
  return context;
};
