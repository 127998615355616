import { Navigate } from 'react-router-dom';
import { EditorRootProvider } from 'lib/rte/provider/EditorRootProvider';
import FullLayout from 'test-builder/pages/layouts/FullLayout';
import HomePage from 'test-builder/pages/HomePage/HomePage';
import YourTestsPage from 'test-builder/pages/YourTestsPage';
import YourQuestionsPage from 'test-builder/pages/YourQuestionsPage';
import QuestionBanks from 'test-builder/pages/QuestionBanks/QuestionBanks';
import QuestionTemplatePage from 'test-builder/pages/QuestionTemplatePage';
import WelcomeScreen from 'test-builder/pages/WelcomeScreen/WelcomeScreen';
import Discipline from 'test-builder/pages/Discipline/Discipline';
import Booktab from 'test-builder/pages/Booktab/Booktab';
import { uploadFile } from 'test-builder/services/file.service';
import ProvidersWrapper from './ProvidersWrapper';
import AuthWrapper from './AuthWrapper';
import ProtectedRoutes from './ProtectedRoutes';

const TestBuilderRoutes = [
  {
    path: '/',
    element: (
      <ProvidersWrapper>
        <AuthWrapper>
          <ProtectedRoutes />
        </AuthWrapper>
      </ProvidersWrapper>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="home" />,
      },
      {
        path: 'home',
        element: (
          <FullLayout>
            <EditorRootProvider imageUploadAPIService={uploadFile}>
              <HomePage />
            </EditorRootProvider>
          </FullLayout>
        ),
        children: [
          {
            path: '',
            element: <Navigate to="tests" />,
          },
          {
            path: 'tests',
            element: <YourTestsPage />,
          },
          {
            path: 'questions',
            element: <YourQuestionsPage />,
          },
          {
            path: 'questionbanks',
            element: <QuestionBanks />,
          },
          {
            path: 'questiontemplate',
            element: <QuestionTemplatePage />,
          },
        ],
      },
      {
        path: 'welcomescreen',
        element: <WelcomeScreen />,
      },
      {
        path: 'discipline',
        element: <Discipline />,
      },
      {
        path: 'booktab',
        element: <Booktab />,
      },
    ],
  },
];

export default TestBuilderRoutes;
