import { useState, useEffect, useRef, useCallback } from 'react';

const useInfiniteScroll = (fetchMoreData, hasMore) => {
  const [page, setPage] = useState(1);
  const loaderRef = useRef(null);

  useEffect(() => {
    if (page > 1) {
      fetchMoreData(page);
    }
  }, [page]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting && hasMore) {
          setPage(prev => prev + 1);
        }
      },
      { threshold: 0 }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [loaderRef, hasMore]);

  const reset = useCallback(() => setPage(1));

  return { loaderRef, page, reset };
};

export default useInfiniteScroll;
