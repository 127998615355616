import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Spinner } from 'react-bootstrap';
import deepEqual from 'deep-equal';
import { Tree } from '@minoru/react-dnd-treeview';
import Placeholder from 'common/components/Placeholder';
import useInfiniteScroll from 'common/hooks/useInfiniteScroll';
import DraggableIcon from 'common/components/DraggableIcon';
import { subscribe, unsubscribe } from 'common/utils/events';
import { useAppContext } from 'test-builder/context/AppContext';
import { useYourQuestionsContext } from 'test-builder/providers/YourQuestions/YourQuestionsContext';
import { getNodeIdsToClose } from 'test-builder/providers/YourQuestions/utils';
import './YourQuestionsTreeView.css';

/**
 * YourQuestionsTreeView component
 *
 * @param {object} props - Component props
 * @param {object} props.selectedFolder - Selected folder
 * @param {function} props.onEditFolderNameClick - Callback for edit folder name click
 */
const YourQuestionsTreeView = ({ selectedFolder, onEditFolderNameClick }) => {
  const intl = useIntl();
  const { dispatchEvent } = useAppContext();
  const {
    tree,
    treeDataAsArray,
    hasMore,
    loadChildFoldersAndQuestions,
    loadRootLevelQuestionsLazy,
    handleQuestionDragAndDrop,
    handleFolderDragAndDrop,
  } = useYourQuestionsContext();

  const treeRef = useRef(null);
  const [selectedQuestionTest, setSelectedQuestionTest] = useState();

  const fetchMore = async page => {
    await loadRootLevelQuestionsLazy(page);
  };

  const { loaderRef, reset } = useInfiniteScroll(fetchMore, hasMore);

  useEffect(() => {
    // Subscribe to the 'reload_your_questions' event to reload data when triggered
    subscribe('reload_your_questions', reset);

    // Return a cleanup function to unsubscribe from the event when the component unmounts
    return () => {
      // Unsubscribe from the 'reload_your_questions' event to prevent memory leaks
      unsubscribe('reload_your_questions', reset);
    };
  }, []);

  /**
   * Load child nodes for a given node
   *
   * @param {object} node - Node to load child nodes for
   */
  const loadChildNodes = node => {
    loadChildFoldersAndQuestions(node);
  };

  /**
   * Handle edit folder name click
   *
   * @param {object} node - Node to edit folder name for
   */
  const handleEditFolderName = node => {
    onEditFolderNameClick(node);
  };

  /**
   * Handle add question to test
   *
   * @param {object} node - Node to add question to test for
   */
  const handleAdd = node => {
    dispatchEvent('ADD_NEW_QUESTION_TO_TEST', node.data);
    setSelectedQuestionTest(node.id);
  };

  /**
   * Handles the drop event for a drag-and-drop operation.
   *
   * @param {object} newTree - The new tree structure after the drop.
   * @param {object} dragSource - The source item being dragged.
   * @param {object} dropTarget - The target item where the drag source is being dropped.
   */
  const handleDrop = async (newTree, { dragSource, dropTarget }) => {
    // Handle question drag-and-drop
    if (dragSource.isQuestion) {
      handleQuestionDragAndDrop(dragSource, dropTarget);
    } else {
      // Ignore when folder is dropped at same position
      if (dragSource?.id === dropTarget?.id) {
        return;
      }

      // Check if folder is being reordered within the same parent
      if (dragSource?.parent === dropTarget?.id || (!dropTarget && dragSource?.parent === 0)) {
        const dropId = dropTarget ? dropTarget.id : 0;
        const newOrder = newTree.filter(item => item.parent === dropId);
        const oldOrder = treeDataAsArray.filter(item => item.parent === dropId);
        if (deepEqual(newOrder, oldOrder)) {
          return null; // no change, do nothing
        }
      }

      // Handle folder drag-and-drop
      await handleFolderDragAndDrop(newTree, dragSource, dropTarget);

      // closes the accordion of dragged folder and its children
      const draggedFolderWithChildIds = getNodeIdsToClose(tree.folders, dragSource.id);
      treeRef.current.close(draggedFolderWithChildIds);
    }
  };

  return (
    <div className="your-questions-treeview treeview" style={{ marginBottom: '-47px' }}>
      <Tree
        tree={treeDataAsArray}
        ref={treeRef}
        sort={false}
        rootId={0}
        canDrag={() => true}
        canDrop={(_currentTree, { dropTarget }) => {
          return !dropTarget ? true : dropTarget.droppable;
        }}
        dropTargetOffset={5}
        onDrop={handleDrop}
        dragPreviewRender={monitorProps => <div className="custom-drag-preview">{monitorProps.item.text}</div>}
        placeholderRender={(node, { depth }) => <Placeholder node={node} depth={depth} />}
        render={(node, { isOpen, onToggle, handleRef }) => (
          <div className="question-folder-tree-node" tabIndex="0" aria-describedby="">
            <DraggableIcon ref={handleRef} />
            {node.droppable && (
              <span
                onClick={() => {
                  if (!isOpen && !node.isChildrenLoaded) {
                    loadChildNodes(node);
                  }
                  onToggle();
                }}
                className="caret-container"
                tabIndex="0"
              >
                <i className={`fa ${isOpen ? 'fa-caret-down' : 'fa-caret-right'}`}></i>
              </span>
            )}
            <div className="tree-node-text flex-grow-1">{node.text}</div>
            <div className="tree-node-action-buttons-container">
              {node.isQuestion ? (
                <button
                  className={`action-button ${selectedQuestionTest === node.id ? 'selected' : ''}`}
                  onClick={() => handleAdd(node)}
                  title={intl.formatMessage({ id: 'addQuestionsIcon', defaultMessage: 'Add Questions' })}
                >
                  <i className="bi bi-plus-lg darker-icon"></i>
                </button>
              ) : (
                <button
                  className={`action-button ${selectedFolder?.id === node.id ? 'selected' : ''}`}
                  onClick={() => handleEditFolderName(node)}
                  title={intl.formatMessage({ id: 'message.edit', defaultMessage: 'Edit' })}
                >
                  <i className="bi bi-pencil-fill"></i>
                </button>
              )}
            </div>
          </div>
        )}
      />
      {hasMore && (
        <div ref={loaderRef} className="lazy-load-spinner">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default YourQuestionsTreeView;
