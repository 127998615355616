import React, { createContext, useEffect, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { convertToSequenceMap, isSourceIsParentOfTarget } from 'common/utils';
import { MANAGE_QUESTION_ACTIONS, bookQuestionsReducer } from './manageQuestionsReducer';
import {
  createFolder,
  deleteFolder,
  deleteQuestion,
  getFolders,
  getQuestions,
  getRootFolderId,
  saveQuestions,
  swapQuestionBetweenFolders,
  updateFolder,
} from 'workspace/api/questions.service';
import { toastify } from 'common/components/Toastify';
import { useLoader } from 'common/providers/LoaderProvider';
import { buildQuestionEnvelop, constructQuestionObject } from 'common/utils/questions-utils';
import {
  convertTreeDataToArray,
  findSelectedFolder,
  getNextSequence,
  getSourceFolderAndNewSequenceNumber,
  isQuestionReOrdering,
} from './utils';

const ManageQuestionsContext = createContext();

// eslint-disable-next-line react-refresh/only-export-components
export const initialData = {
  activeQuestions: [],
  treeData: null,
  treeDataAsArray: [],
  selectedFolder: null,
  selectedBook: null,
};

const ManageQuestionsProvider = ({ children }) => {
  const intl = useIntl();
  const { showLoader, hideLoader } = useLoader();
  const [state, dispatch] = useReducer(bookQuestionsReducer, initialData);

  useEffect(() => {
    if (state.treeData) {
      const treeDataAsArray = convertTreeDataToArray(state.treeData.children, state.treeData.guid);
      dispatch({ type: MANAGE_QUESTION_ACTIONS.UPDATE_FOLDERS_TREE_DATA_ARRAY, payload: treeDataAsArray });
    }
  }, [state.treeData]);

  const addQuestionToTheList = question => {
    dispatch({ type: MANAGE_QUESTION_ACTIONS.ADD_QUESTION, payload: question });
  };

  const updateSelectedBook = book => {
    dispatch({ type: MANAGE_QUESTION_ACTIONS.UPDATE_SELECTED_BOOK, payload: book });
    loadRootLevelFoldersAndQuestions(book.guid);
  };

  const loadRootLevelFoldersAndQuestions = async (bookId, isReload) => {
    showLoader();
    try {
      const { guid, questionBindings, ...others } = isReload ? state.treeData : await getRootFolderId(bookId);

      const sequenceMap = convertToSequenceMap(questionBindings, 'questionId');

      // Fetch root level folders and questions
      let [folders, questions] = await Promise.all([getFolders(bookId, guid), getQuestions(bookId, guid)]);

      folders.sort((a, b) => a.sequence - b.sequence);

      questions = questions
        .map(question => {
          try {
            return { ...constructQuestionObject(question), sequence: sequenceMap[question.guid] };
          } catch (error) {
            return null;
          }
        })
        .filter(question => question);

      questions.sort((a, b) => a.sequence - b.sequence);

      dispatch({
        type: MANAGE_QUESTION_ACTIONS.UPDATE_ROOT_LEVEL_FOLDERS_QUESTIONS,
        payload: { ...others, guid, children: { folders, questions }, questionBindings },
      });
    } catch (error) {
      toastify.showErrorToast(error?.message);
    } finally {
      hideLoader();
    }
  };

  /**
   * Load child folders and questions
   * @param {object} node - Folder node
   */
  const loadChildFoldersAndQuestions = async node => {
    showLoader();
    try {
      // Fetch child folders and questions
      let [folders, questions] = await Promise.all([
        getFolders(state.selectedBook.guid, node.id),
        getQuestions(state.selectedBook.guid, node.id),
      ]);

      // Sort folders by sequence
      folders = folders.slice().sort((a, b) => a.sequence - b.sequence);

      const sequenceMap = convertToSequenceMap(node.questionBindings, 'questionId');
      // Add QTI model to questions
      questions = questions
        .map(question => {
          try {
            return { ...constructQuestionObject(question), sequence: sequenceMap[question.guid] };
          } catch (error) {
            return null;
          }
        })
        .filter(question => question);

      questions.sort((a, b) => a.sequence - b.sequence);

      // Update state with new folders and questions
      dispatch({
        type: MANAGE_QUESTION_ACTIONS.INSERT_FOLDERS_AND_QUESTIONS,
        payload: { folders, questions, parentId: node.id },
      });
    } catch (error) {
      toastify.showErrorToast(error?.message);
    } finally {
      hideLoader();
    }
  };

  /**
   * Adds a new folder.
   *
   * @param {string} folderName - The name of the folder to be created.
   * @returns {Promise<void>} - A promise that resolves when the operation is complete.
   */
  const addNewFolder = async title => {
    showLoader();

    try {
      const parentId = state.selectedFolder || state.treeData.guid;
      const bookId = state.selectedBook.guid;
      const parentFolder = state.selectedFolder
        ? findSelectedFolder(state.treeData.children.folders, parentId)
        : state.treeData;
      const sequence = getNextSequence(parentFolder.children?.folders || []);

      const payload = {
        title,
        sequence,
        parentId,
        bookId,
      };

      // API call to create a new folder
      const newFolder = await createFolder(bookId, payload);

      // Dispatch action to add the new folder to the state
      dispatch({
        type: MANAGE_QUESTION_ACTIONS.ADD_FOLDER,
        payload: newFolder,
      });

      // Show success toast notification
      toastify.showSuccessToast(intl.formatMessage({ id: 'success.folder.save' }));
    } catch (error) {
      if (error?.status === 409) {
        toastify.showWarningToast(error?.message);
      } else {
        toastify.showErrorToast(intl.formatMessage({ id: 'error.FailedToSaveFolder' }));
      }
    } finally {
      hideLoader();
    }
  };

  /**
   * Deletes a folder from the selected book and updates the application state.
   *
   * @param {Object} folder - The folder object to be deleted.
   * @param {string} folder.id - The unique identifier of the folder to be deleted.
   * @returns {Promise<void>} A promise that resolves when the folder is deleted.
   */
  const deleteFolderFromBook = async folder => {
    showLoader();

    const bookId = state.selectedBook.guid;

    try {
      // API call to delete folder
      await deleteFolder(bookId, folder.id);

      // Dispatch action to delete the folder from state
      dispatch({
        type: MANAGE_QUESTION_ACTIONS.DELETE_FOLDER,
        payload: { folder },
      });

      // Show success toast notification
      toastify.showSuccessToast(intl.formatMessage({ id: 'success.folder.delete' }));
    } catch (error) {
      toastify.showErrorToast(error?.message);
    } finally {
      hideLoader();
    }
  };

  /**
   * Updates the name of a specified folder and dispatches the update action.
   *
   * @param {Object} folder - The folder object to be updated.
   * @param {string} folderName - The new name for the folder.
   * @returns {Promise<void>} - A promise that resolves when the folder name is updated.
   */
  const updateFolderName = async (folder, title) => {
    showLoader();

    const { id: guid, parent: parentId, sequence } = folder;
    const bookId = state.selectedBook.guid;

    const payload = {
      guid,
      title,
      sequence,
      parentId,
      bookId,
    };

    try {
      // API call to update folder name
      await updateFolder(bookId, payload);

      // Dispatch action to update the folder name
      dispatch({
        type: MANAGE_QUESTION_ACTIONS.UPDATE_FOLDER_NAME,
        payload: { folder, title },
      });

      // Show success toast notification
      toastify.showSuccessToast(intl.formatMessage({ id: 'success.folder.update' }));
    } catch (error) {
      if (error?.status === 409) {
        toastify.showWarningToast(error?.message);
      } else {
        toastify.showErrorToast(intl.formatMessage({ id: 'error.FailedToUpdateFolder' }));
      }
    } finally {
      hideLoader();
    }
  };

  const updateSelectedFolder = folder => {
    dispatch({ type: MANAGE_QUESTION_ACTIONS.UPDATE_SELECTED_FOLDER, payload: folder });
  };

  /**
   * Handle folder drag and drop
   * @param {object} newTree - New tree data
   * @param {object} dragSource - Drag source node
   * @param {object} dropTarget - Drop target node
   */
  const handleFolderDragAndDrop = async (newTree, dragSource, dropTarget) => {
    if (isSourceIsParentOfTarget(state.treeDataAsArray, dragSource.id, dropTarget?.id || state.treeData.guid)) {
      toastify.showWarningToast(intl.formatMessage({ id: 'info.dragFolderToItsOwnChildFolders' }));
      return;
    } else {
      showLoader();
      try {
        const { sourceFolder, sequence, error } = getSourceFolderAndNewSequenceNumber(
          state,
          newTree,
          dragSource,
          dropTarget
        );

        if (error === 'duplicate_folder_name') {
          toastify.showWarningToast(
            intl.formatMessage({ id: 'error.folder.alreadyExists' }, { title: dragSource.text })
          );
          return;
        }

        const bookId = state.selectedBook.guid;

        // Create payload for updated folder
        const payload = {
          guid: sourceFolder.id,
          parentId: sourceFolder.parent,
          questionBindings: sourceFolder.questionBindings,
          sequence,
          title: sourceFolder.text,
          bookId,
        };

        // API call to update folder rearrangement
        await updateFolder(bookId, payload);

        // Update state with new folder position
        dispatch({
          type: MANAGE_QUESTION_ACTIONS.REARRANGE_FOLDER,
          payload: { dragSource, dropTarget, updatedFolder: payload },
        });

        toastify.showSuccessToast(intl.formatMessage({ id: 'success.folder.rearrange' }));
      } catch (error) {
        if (error.status === 409) {
          toastify.showWarningToast(error.message);
        } else {
          toastify.showErrorToast(intl.formatMessage({ id: 'error.FailedToRearrangeFolder' }));
        }
      } finally {
        hideLoader();
      }
    }
  };

  /**
   * Handles the drag-and-drop operation for questions, including reordering and swapping between folders.
   * @param {Object} newTree - The updated tree structure after the drag-and-drop operation.
   * @param {Object} dragSource - The node being dragged (source of the drag operation).
   * @param {Object} dropTarget - The node where the dragged item is dropped (target of the drag operation).
   * @returns {Promise<void>} - A promise that resolves when the operation is complete.
   */
  const handleQuestionDragAndDrop = async (newTree, dragSource, dropTarget) => {
    showLoader();

    try {
      if (isQuestionReOrdering(dragSource, dropTarget, state.treeData.guid)) {
        await handleQuestionReordering(newTree, dropTarget);
      } else {
        await handleQuestionSwap(dragSource, dropTarget);
      }
    } catch (error) {
      toastify.showErrorToast(error?.message);
    } finally {
      hideLoader();
    }
  };

  /**
   * Handles the reordering of questions within the same folder.
   * @param {Object} newTree - The updated tree structure after the drag-and-drop operation.
   * @param {Object} dropTarget - The node where the dragged item is dropped (target of the drag operation).
   * @returns {Promise<void>} - A promise that resolves when the reordering is complete.
   */
  const handleQuestionReordering = async (newTree, dropTarget) => {
    const folder = dropTarget ? findSelectedFolder(state.treeData.children.folders, dropTarget.id) : state.treeData;

    const allQuestions = newTree.filter(item => item.parent === folder.guid && item.isQuestion);

    const questionBindings = allQuestions.map((question, index) => ({
      questionId: question.id,
      sequence: index + 1,
    }));

    const { guid, parentId, sequence, title } = folder;
    const bookId = state.selectedBook.guid;

    const payload = {
      guid,
      sequence,
      parentId,
      bookId,
      title,
      questionBindings,
    };

    // API call to update question bindings
    await updateFolder(bookId, payload);

    dispatch({
      type: MANAGE_QUESTION_ACTIONS.REORDER_QUESTIONS,
      payload: {
        folder,
        questionBindings,
      },
    });

    toastify.showSuccessToast(intl.formatMessage({ id: 'success.questionReOrderSuccessfully' }));
  };

  /**
   * Handles swapping a question between two folders.
   * @param {Object} dragSource - The node being dragged (source of the drag operation).
   * @param {Object} dropTarget - The node where the dragged item is dropped (target of the drag operation).
   * @returns {Promise<void>} - A promise that resolves when the swap is complete.
   */
  const handleQuestionSwap = async (dragSource, dropTarget) => {
    const bookId = state.selectedBook.guid;
    // Get source and destination folder IDs
    const sourceFolderId = dragSource.parent;
    const destinationFolderId = dropTarget ? dropTarget.id : state.treeData.guid;
    // Swap question between folders
    await swapQuestionBetweenFolders(bookId, sourceFolderId, destinationFolderId, dragSource.id);

    // Update state with new question position
    dispatch({
      type: MANAGE_QUESTION_ACTIONS.SWAP_QUESTION_BETWEEN_FOLDERS,
      payload: {
        dragSource,
        dropTarget,
      },
    });

    toastify.showSuccessToast(intl.formatMessage({ id: 'success.questionMovedSuccessfully' }));
  };

  const questionViewButtonClicked = payload => {
    dispatch({ type: MANAGE_QUESTION_ACTIONS.VIEW_QUESTION_CLICK, payload });
  };

  const questionEditButtonClicked = index => {
    dispatch({ type: MANAGE_QUESTION_ACTIONS.EDIT_QUESTION_CLICK, payload: index });
  };

  const questionDeleteButtonClicked = index => {
    dispatch({ type: MANAGE_QUESTION_ACTIONS.DELETE_QUESTION_CLICK, payload: index });
  };

  const clearManageQuestionsState = () => {
    dispatch({ type: MANAGE_QUESTION_ACTIONS.CLEAR_STATE });
  };

  /**
   * Saves the active questions to a specified folder in the selected book.
   *
   * This function builds question envelopes from the active questions in the state,
   * makes an API call to save them, and dispatches an action to update the state.
   *
   * @returns {Promise<void>} A promise that resolves when the questions are saved successfully.
   * @throws {Error} Throws an error if the API call fails.
   */
  const saveQuestionsToFolder = async () => {
    showLoader();
    const bookId = state.selectedBook.guid;
    const folderId = state.selectedFolder || state.treeData.guid;

    try {
      // Build question envelopes for saving to the API
      const questionsToSave = state.activeQuestions?.map(question => buildQuestionEnvelop(question, {})) || [];

      // API call to save questions
      const response = await saveQuestions(bookId, folderId, questionsToSave);

      // Construct question objects from the API response
      const questions = questionsToSave.map((question, index) => {
        const { body, metadata } = question;

        return constructQuestionObject({
          guid: response[index].guid,
          qtixml: body,
          metadata,
        });
      });

      // Dispatch action to update state
      dispatch({
        type: MANAGE_QUESTION_ACTIONS.SAVE_QUESTIONS,
        payload: {
          questions,
          folderId,
        },
      });

      // Show success toast notification
      toastify.showSuccessToast(intl.formatMessage({ id: 'success.questions.save' }));
    } catch (error) {
      toastify.showErrorToast(intl.formatMessage({ id: 'error.questions.save' }));
    } finally {
      hideLoader();
    }
  };

  const updateQuestionInsideTree = (question, folderId) => {
    dispatch({ type: MANAGE_QUESTION_ACTIONS.UPDATE_QUESTION, payload: { question, folderId } });
  };

  /**
   * Deletes a question from a specific folder in the selected book and updates the application state.
   *
   * @param {Object} question - The question object to be deleted.
   * @param {string} question.guid - The unique identifier (GUID) of the question to be deleted.
   * @param {string} folderId - The ID of the folder containing the question.
   * @param {string} bookId - The ID of the book containing the folder.
   * @returns {Promise<void>} A promise that resolves when the question is deleted.
   */
  const deleteQuestionFromFolder = async (folderId, questionGuid) => {
    showLoader();

    const bookId = state.selectedBook.guid;
    try {
      // Call the API to delete the question
      await deleteQuestion(bookId, folderId, questionGuid);

      // Dispatch action to update the state and remove the question from the folder
      dispatch({
        type: MANAGE_QUESTION_ACTIONS.DELETE_QUESTION,
        payload: { folderId, questionGuid },
      });

      // Show success toast notification
      toastify.showSuccessToast(intl.formatMessage({ id: 'success.question.delete' }));
    } catch (error) {
      // Show error toast in case of failure
      toastify.showErrorToast(intl.formatMessage({ id: 'error.question.delete' }));
    } finally {
      hideLoader();
    }
  };

  const contextValue = {
    ...state,
    updateSelectedBook,
    loadRootLevelFoldersAndQuestions,
    addQuestionToTheList,
    updateSelectedFolder,
    addNewFolder,
    deleteFolderFromBook,
    updateFolderName,
    handleFolderDragAndDrop,
    handleQuestionDragAndDrop,
    questionViewButtonClicked,
    questionEditButtonClicked,
    questionDeleteButtonClicked,
    clearManageQuestionsState,
    loadChildFoldersAndQuestions,
    saveQuestionsToFolder,
    updateQuestionInsideTree,
    deleteQuestionFromFolder,
  };

  // console.log('contextValue::', contextValue);

  return <ManageQuestionsContext.Provider value={contextValue}>{children}</ManageQuestionsContext.Provider>;
};

export default ManageQuestionsProvider;

// eslint-disable-next-line react-refresh/only-export-components
export const useManageQuestionsContext = () => {
  const context = React.useContext(ManageQuestionsContext);
  if (!context) {
    throw new Error('useBookQuestions must be used within a QuestionsProvider');
  }

  return context;
};
