import axios from 'test-builder/api/axios';

let url = process.env.REACT_APP_API_URL;

export const getUserProfilesettings = async () => {
  return axios.get(`${url}/settings/questionmetadata`);
};

export const saveUserProfileQuestionMetadata = async selectedSettings => {
  return axios.post(`${url}/settings/questionmetadata`, selectedSettings);
};
