import React, { useEffect } from 'react';
import YourTests from 'test-builder/components/YourTests/YourTests';

const YourTestsPage = () => {
  useEffect(() => {
    document.title = 'Your Tests';
  }, []);
  return <YourTests />;
};

export default YourTestsPage;
